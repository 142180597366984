export const emptyOption = {
  name: '',
  value: '',
  type_id: null,
};

export const INITIAL_VALUES = {
  options: [
    emptyOption,
  ],
  previews: [
    {
      url: null,
    },
    {
      url: null,
    },
  ],
  images: [
    {
      url: null,
    },
    {
      url: null,
    },
  ],
};

import { put } from 'redux-saga/effects';
import API from '../api';
import { setOptionsTypes } from '../actions/optionsTypes';

export function* getOptionsTypesSaga() {
  try {
    const json = yield API.rest.optionsType.list();
    yield put(setOptionsTypes(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* addOptionsTypeSaga(action) {
  try {
    yield API.rest.optionsType.add(action.$payload);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

// eslint-disable-next-line max-classes-per-file
import { HELPERS } from '../helpers';

export class ErrorNotAuthorized extends Error {
  constructor(response) {
    super(['Ошибка авторизации', HELPERS.parseErrors(response.data)].join('\n'));
    this.response = response;
    this.name = 'ErrorNotAuthorized';
    this.error = response.data.error;
    this.detail = response.data;
    this.constructor = ErrorNotAuthorized;
    // eslint-disable-next-line no-proto
    this.__proto__ = ErrorNotAuthorized.prototype;
  }
}

export class ErrorForbidden extends Error {
  constructor(response) {
    super(['Операция запрещена', HELPERS.parseErrors(response.data)].join('\n'));
    this.error = response.data.error;
    this.detail = response.data;
    this.response = response;
    this.name = 'ErrorForbidden';
    this.constructor = ErrorForbidden;
    // eslint-disable-next-line no-proto
    this.__proto__ = ErrorForbidden.prototype;
  }
}

export class ErrorBadRequest extends Error {
  constructor(response) {
    super(['Некорректный запрос:', HELPERS.parseErrors(response.data)].join('\n'));
    this.error = response.data.error;
    this.detail = response.data;
    this.response = response;
    this.name = 'ErrorBadRequest';
    this.constructor = ErrorBadRequest;
    // eslint-disable-next-line no-proto
    this.__proto__ = ErrorBadRequest.prototype;
  }
}

export class ErrorBackend extends Error {
  constructor(response) {
    super(['Ошибка сервера', HELPERS.parseErrors(response.data)].join('\n'));
    this.error = response.data.error;
    this.detail = response.data;
    this.response = response;
    this.name = 'ErrorBackend';
    this.constructor = ErrorBackend;
    // eslint-disable-next-line no-proto
    this.__proto__ = ErrorBackend.prototype;
  }
}

export class ErrorUnknown extends Error {
  constructor(response) {
    super(['Неизвестная ошибка', HELPERS.parseErrors(response.data)].join('\n'));
    this.error = response.data.error;
    this.detail = response.data || response.data.non_field_errors;
    this.response = response;
    this.name = 'ErrorUnknown';
    this.constructor = ErrorUnknown;
    // eslint-disable-next-line no-proto
    this.__proto__ = ErrorUnknown.prototype;
  }
}

export default {
  BadRequest: ErrorBadRequest,
  Backend: ErrorBackend,
  Forbidden: ErrorForbidden,
  NotAuthorized: ErrorNotAuthorized,
  Unknown: ErrorUnknown,
};

import { put, select, call } from 'redux-saga/effects';
import {
  setCatalogueFilters,
  setCatalogueList,
  setLikeCatalogueItem,
  setFlagRemovedProduct,
  setRemoveProduct,
  setLoadingFlagProduct,
} from '../actions/catalogue';
import API from '../api';
import { isAuthorizedSelector } from '../selectors/profile';
import { localStorageKeyFavoritesItems } from '../constants';
import { getDataFromLocalStorage } from '../helpers';
import { history } from '../store';
import { routes } from '../routes';
import { setFavoriteItem } from '../actions/favorites';
import { removeFavoriteItemSaga } from './favorites';
import { setNotification } from '../actions/notification';

export function* getListSaga(action) {
  try {
    const { keepPreviousValues, saveFilters = false, query } = action.$payload;
    const { selectedSubCategory, selectedCategory, ...querys } = query;
    const json = yield API.rest.catalogue.get({
      ...querys,
      ...selectedSubCategory ? { category_id: selectedSubCategory } : {},
    });
    yield put(setCatalogueList({ ...json, keepPreviousValues }));
    history.push(routes.catalogue('', query));
    if (saveFilters) {
      yield put(setCatalogueFilters(prepareFilters(json)));
    } // сохраняются не значения фильтров, а сами возможные фильтры
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* createProductSaga(action) {
  try {
    yield API.rest.catalogue.create(prepareDataCreateProduct(action.$payload.values));
    yield put(setNotification({
      type: 'success',
      message: 'Товар успешно создан!',
    }));
    action?.$payload?.onFinish?.();
    // yield put(setCatalogueList(json));
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка создания товара',
    }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* updateProductSaga(action) {
  try {
    yield API.rest.catalogue.update(action.$payload);
    // yield put(setCatalogueList(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadingFlagProduct({ value: false }));
  }
}

export function* removeProductSaga(action) {
  try {
    yield API.rest.catalogue.deleteItem(action.$payload);
    yield put(setRemoveProduct(action.$payload));
    yield put(setFlagRemovedProduct({ value: true }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadingFlagProduct({ value: false }));
  }
}

// export function* getCatalogueFiltersSaga(action) {
//   try {
//     // const json = yield API.rest.catalogue.getFilters(action.$payload);
//     yield put(setCatalogueFilters(mockFilterDto));
//   } catch (err) {
//     console.log(err);
//   }
// }

export function* likeItemSaga(action) {
  try {
    const { id, liked, bodyForLocalStorage } = action.$payload;
    const isAuthorized = yield select(isAuthorizedSelector);
    const favoritesInLocalStorage = getDataFromLocalStorage(localStorageKeyFavoritesItems);
    if (!isAuthorized) {
      localStorage.removeItem(localStorageKeyFavoritesItems);
      if (liked) {
        localStorage.setItem(localStorageKeyFavoritesItems, JSON.stringify([
          ...favoritesInLocalStorage,
          bodyForLocalStorage,
        ]));
        yield put(setFavoriteItem(bodyForLocalStorage));
      } else {
        localStorage.setItem(
          localStorageKeyFavoritesItems,
          JSON.stringify(favoritesInLocalStorage.filter((el) => el.productId !== id)),
        );
        yield call(removeFavoriteItemSaga, {
          $payload: { id, productId: bodyForLocalStorage.productId },
        });
      }
    } else if (liked) {
      const json = yield API.rest.favorites.add({ product_id: id });
      yield put(setFavoriteItem({
        ...bodyForLocalStorage,
        id: json.id,
        count: json.count,
      }));
    } else {
      // yield API.rest.favorites.del({ id });
      yield call(removeFavoriteItemSaga, {
        $payload: { id, productId: bodyForLocalStorage.productId },
      });
    }
    yield put(setLikeCatalogueItem(id));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

const prepareDataCreateProduct = (data) => ({
  ...data,
  categories: [data.subcategories],
  previews: data.previews.filter((el) => !!el.url),
  images: data.images.filter((el) => !!el.url),
});

export const prepareFilters = (data) => {
  let filtersList = [];

  if (data.filters?.options) {
    const optionsFilters = data.filters?.options.map((el) => {
      if (el.type.name === 'range') {
        return (
          {
            id: el.id,
            filterName: el.name,
            name: el.id,
            variant: 'range',
            values: [
              parseInt(el.min, 10),
              parseInt(el.max, 10),
            ], // TODO Уточнить, мб там parseFloat надо
          }
        );
      }
      if (el.type.name === 'list') {
        return (
          {
            id: el.id,
            filterName: el.name,
            name: el.id, // не нужен?
            variant: 'select',
            values: el.values.map((el) => ({ id: el, name: el })),
          }
        );
      }
      // eslint-disable-next-line no-console
      console.error('Error! Unexpected type.name in filters');
      return {};
    });
    filtersList = [
      ...filtersList,
      ...optionsFilters,
    ];
  }

  if (data.filters?.prices) {
    filtersList = [
      ...filtersList,
      {
        id: 'prices',
        filterName: 'Цена',
        name: 'prices',
        variant: 'range',
        values: [data.filters.prices.min, data.filters.prices.max],
      },
    ];
  }

  if (data.filters?.colors) {
    filtersList = [
      ...filtersList,
      {
        id: 'colors',
        filterName: 'Цвет',
        name: 'colors', // не нужен?
        variant: 'colors',
        values: [...data.filters.colors],
      },
    ];
  }
  return filtersList;
};

import { put } from 'redux-saga/effects';
import API from '../api';
import { setAddress } from '../actions/yandex';
// import {setNotification} from "../actions/notifications";

export function* yandexSearchSaga(action) {
  try {
    const json = yield API.rest.yandex.searchByAddress(action.$payload.data);
    yield put(setAddress({
      ...json,
    }));
    action.$payload.onFinish(json);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    // yield put(setNotification({
    //     type: 'error',
    //     title: 'Ошибка',
    //     message: 'Не удалось найти'
    // }))
  }
}

export function* yandexSearchCoordsSaga(action) {
  try {
    const json = yield API.rest.yandex.searchByAddress(action.$payload.data);
    // yield put( setAddress({
    //     ...json,
    // }));
    action.$payload.onFinish(json);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    // yield put(setNotification({
    //     type: 'error',
    //     title: 'Ошибка',
    //     message: 'Не удалось найти'
    // }))
  }
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategories,
  clearCategories,
  getColors,
  clearColors,
} from '../../../actions/user';
import './index.scss';
import { ColorsPanel } from './ColorsPanel';
import { AddGood } from './addGood';
import { clearOptionsTypes, getOptionsTypes } from '../../../actions/optionsTypes';
import { CategoriesPanel } from './CategoriesPanel';
import { Roles } from './roles';
import { Subcategories } from './subcategories';
import { CommonBanner } from './commonBanner';
import { SellerBanner } from './sellerBanner';

export const AdminPanel = () => {
  const dsp = useDispatch();
  const { categories, subcategories, colors, user } = useSelector((state) => state.profile);
  const { optionsTypes } = useSelector((state) => state.optionsTypes);
  const { role } = user || {};

  useEffect(() => {
    dsp(getCategories());
    dsp(getColors());
    dsp(getOptionsTypes());
    return () => {
      dsp(clearCategories());
      dsp(clearColors());
      dsp(clearOptionsTypes());
    };
  }, [dsp]);

  return (
    <div className="AdminPanel">
      <div className="AdminPanel_title">Панель администрирования</div>
      <div className="AdminPanel_content">
        {
          role === 'admin' && (
            <CommonBanner />
          )
        }
        <SellerBanner />
        {
          role === 'admin' && (
            <>
              <CategoriesPanel
                categories={categories}
              />
              <Subcategories
                categories={categories}
                subcategories={subcategories}
              />
              <ColorsPanel />
              <Roles />
            </>
          )
        }
        <AddGood
          colors={colors}
          categories={categories}
          subcategories={subcategories}
          optionsTypes={optionsTypes}
        />
      </div>
    </div>
  );
};

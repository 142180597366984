import types from '../types';

const initialState = {
  open: {
    value: false,
    callback: null, // для перехода в категорию после выбора возраста
  },
  isAdult: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ADULT_MODAL_VALUE:
      return {
        ...state,
        open: action.$payload,
      };
    case types.SET_ADULT_VALUE:
      return {
        ...state,
        isAdult: action.$payload.value,
      };
    case types.CLEAR_ADULT_STORE:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logoutUser } from '../../actions/user';
import { routes } from '../../routes';
import { ReactComponent as Arrow } from '../../assets/filterWrapper/Arrow.svg';
import BreadCrumbs from '../../components/breadCrumbs';
import './index.scss';
import { UserData } from './userData';
import { AdminPanel } from './admin';
import { OrdersHistory } from './ordersHistory';

export const profileSelector = (state) => state.profile.user;

const breadcrumbsList = [
  {
    id: 1,
    link: routes.catalogue(),
    title: 'Главная',
  },
  {
    id: 2,
    link: routes.profile(),
    title: 'Личный кабинет',
  },
];

const Profile = () => {
  const [page, setPage] = useState('profile');
  const user = useSelector(profileSelector);
  const dsp = useDispatch();
  const role = user?.role;

  useEffect(
    () => {
      dsp(getUser());
    },
    [dsp],
  );

  const menuList = useMemo(() => [
    ...(role === 'admin' || role === 'seller') ? [{
      id: 'admin',
      name: 'Администрирование',
      onClick: () => {
        setPage('admin');
      },
    }] : [],
    {
      id: 'profile',
      name: 'Профиль',
      onClick: () => {
        setPage('profile');
      },
    },
    {
      id: 'history',
      name: 'История заказов',
      onClick: () => {
        setPage('history');
      },
    },
  ], [role]);

  const onExit = () => {
    dsp(logoutUser());
  };

  const handleGoUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="Profile">
      <BreadCrumbs list={breadcrumbsList} />
      <div className="title">Личный кабинет</div>
      <div className="Profile_content">
        <div className="menu">
          {
            menuList.map((el) => (
              <button
                key={el.id}
                type="button"
                onClick={el.onClick}
                className={cn('menu_item', { active: el.id === page })}
              >
                <div>{el.name}</div>
                <Arrow />
              </button>
            ))
          }
          <button type="button" onClick={onExit} className="exit">
            Выход
          </button>
        </div>
        <div className="subPage">
          {page === 'profile' && (
            <UserData />
          )}
          {page === 'admin' && (
            <AdminPanel />
          )}
          {page === 'history' && (
            <OrdersHistory />
          )}
        </div>
      </div>
      <button className="goUp" onClick={handleGoUp}>
        <Arrow />
      </button>
    </div>
  );
};
export default Profile;

import types from '../types';

const initialState = {
  selectedCategory: null,
  selectedSubCategory: null,
  list: null,
  totalPages: null,
  totalRecords: null,
  filters: null,
  flagRemoved: false,
  loading: false,
  loadingList: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECT_COMMON_CATEGORY:
      return {
        ...state,
        selectedCategory: action.$payload.id,
      };
    case types.SET_SELECT_COMMON_SUBCATEGORY:
      return {
        ...state,
        selectedSubCategory: action.$payload.id,
      };
    case types.SET_REMOVE_PRODUCT:
      return {
        ...state,
        list: [...state.list].filter((el) => el.id !== action.$payload),
      };
    case types.SET_FLAG_REMOVED_PRODUCT:
      return {
        ...state,
        flagRemoved: action.$payload.value,
      };
    case types.FETCH_UPDATE_PRODUCT:
    case types.FETCH_REMOVE_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case types.SET_LOADING_FLAG_PRODUCT:
      return {
        ...state,
        loading: action.$payload.value,
      };
    case types.GET_CATALOGUE_LIST:
      return {
        ...state,
        loadingList: true,
      };
    case types.SET_CATALOGUE_LIST:
      // eslint-disable-next-line no-case-declarations
      const catalogueList = action.$payload.products.data;
      // const catalogueList = parseCatalogueList(action.$payload.products.data);
      return {
        ...state,
        list: action.$payload.keepPreviousValues
          ? [...state.list ? state.list : [], ...catalogueList]
          : [...catalogueList],
        totalPages: action.$payload.products.last_page,
        // totalPages: Math.ceil(action.$payload.products.total / 10),
        totalRecords: action.$payload.products.total,
        loadingList: false,
      };
    case types.DEL_CATALOGUE_LIST:
      return {
        ...state,
        list: null,
        totalPages: null,
        totalRecords: null,
        loadingList: false,
      };
    case types.SET_LIKE_CATALOGUE_ITEM:
      return {
        ...state,
        list: state.list.map((el) => ({
          ...el,
          liked: el.id === action.$payload ? !el.liked : el.liked,
        })),
      };
    case types.SET_CATALOGUE_FILTERS:
      return {
        ...state,
        filters: action.$payload,
      };
    case types.DEL_CATALOGUE_FILTERS:
      return {
        ...state,
        filters: null,
      };
    default:
      return state;
  }
};

export default reducer;

// const parseCatalogueList = (list) => {
//   return list.map((el) => ({
//     ...el,
//     liked: el.is_favorite,
//   }));
// };

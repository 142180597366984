import { put } from 'redux-saga/effects';
import API from '../api';
import { setDeliveryPrice } from '../actions/delivery';

export function* getDeliveryPriceSaga() {
  try {
    const json = yield API.rest.deliveryPrice.get();
    yield put(setDeliveryPrice(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

// export function* updateDeliveryPriceSaga(action) {
//   try {
//     const json = yield API.rest.deliveryPrice.update(action.$payload);
//     yield put(setDeliveryPrice(json));
//   } catch (err) {
//     // eslint-disable-next-line no-console
//     console.error(err);
//   }
// }

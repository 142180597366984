import { createAction } from './common';
import types from '../types';

export const sendOrderList = createAction(types.SEND_ORDER_LIST);
export const setOrderList = createAction(types.SET_ORDER_LIST);
export const setSendedOrderList = createAction(types.SET_SENDED_ORDER_LIST);
export const clearOrderList = createAction(types.CLEAR_ORDER_LIST);

export const getHistoryOrdersList = createAction(types.GET_HISTORY_ORDERS_LIST);
export const setHistoryOrdersList = createAction(types.SET_HISTORY_ORDERS_LIST);
export const clearHistoryOrdersList = createAction(types.CLEAR_HISTORY_ORDERS_LIST);

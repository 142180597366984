import { put } from 'redux-saga/effects';
import API from '../api';
import { setHistoryOrdersList, setSendedOrderList } from '../actions/order';
import { setNotification } from '../actions/notification';

export function* getOrderListSaga() {
  try {
    const json = yield API.rest.order.list();
    yield put(setHistoryOrdersList(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* sendOrderListSaga({ $payload }) {
  try {
    yield API.rest.order.add({ products: parseOrderList($payload) });
    yield put(setSendedOrderList({ value: true }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    // console.log(err?.data.errors);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка оформления заказа',
    }));
    yield put(setSendedOrderList({ value: false }));
  }
}

const parseOrderList = (list) => list.map((el) => ({
  price: el.price,
  count: el.count,
  // color_id: el.color, // убрать
  id: el.id,
}));

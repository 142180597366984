import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useDispatch } from 'react-redux';
import { addSellerBanner, removeSellerBanner } from '../../../../actions/banner';
import { TextField } from '../../../../components/simpleComponents/textField';
import { PhotoEditor } from '../../../../components/photoEditor';

export const SellerBanner = () => {
  const [linkForAdd, setLinkForAdd] = useState('');
  const [bannerImageForAdd, setBannerImageForAdd] = useState(null);
  const dsp = useDispatch();

  const addBanner = () => {
    dsp(addSellerBanner({
      url: bannerImageForAdd,
      link: linkForAdd,
    }));
  };

  const deleteBanner = () => {
    dsp(removeSellerBanner({}));
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        Баннер продавца
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div>Добавить баннер</div>
          <TextField
            placeholder="Ссылка баннера"
            value={linkForAdd}
            error={!linkForAdd ? 'Обязательное поле' : undefined}
            onChange={(e) => {
              setLinkForAdd(e.target.value);
            }}
          />
          <PhotoEditor
            needWidth={1281}
            needHeight={537}
            label="Картинка баннера"
            onChange={setBannerImageForAdd}
            value={bannerImageForAdd}
          />
          <button
            onClick={addBanner}
            disabled={!linkForAdd || !bannerImageForAdd}
          >
            Добавить баннер
          </button>
          <div>Редактировать баннер</div>
          <div>Удалить баннер</div>
          <button
            onClick={deleteBanner}
          >
            Удалить баннер
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { TextField } from '../../../../components/simpleComponents/textField';
import { SelectMui } from '../../../../components/simpleComponents/selectMui';
import { addColor, delColor } from '../../../../actions/user';

export const ColorsPanel = () => {
  const [currentColor, setCurrentColor] = useState('#000000');
  const [currentColorName, setCurrentColorName] = useState('');
  const [currentColorNameTouched, setCurrentColorNameTouched] = useState(false);
  const [currentDeletedColor, setCurrentDeletedColor] = useState(null);
  const { colors } = useSelector((state) => state.profile) || [];

  const dsp = useDispatch();

  const handleAddColor = useCallback(() => {
    dsp(addColor({
      values: {
        name: currentColorName,
        rgb: currentColor,
      },
      onFinish: () => {
        setCurrentColor(null);
      },
    }));
  }, [currentColor, currentColorName, dsp]);

  const handleDeleteColor = useCallback(() => {
    dsp(delColor({
      value: currentDeletedColor,
      onFinish: () => {
        setCurrentDeletedColor(null);
      },
    }));
  }, [currentDeletedColor, dsp]);

  const handleChangeColorName = useCallback((e) => {
    setCurrentColorNameTouched(true);
    setCurrentColorName(e.target.value);
  }, []);

  const colorsOptions = useMemo(
    () => (colors ? colors.map((el) => ({ label: el.name, value: el.id })) : []),
    [colors],
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        Цвета товаров
      </AccordionSummary>
      <AccordionDetails>
        <div className="ColorsPanel">
          {/* <div>Цвета товаров</div> */}
          <div className="row">
            <div className="addColor">
              <div className="label">Код цвета</div>
              <input
                placeholder="Код цвета"
                type="color"
                value={currentColor}
                onChange={(e) => setCurrentColor(e.target.value)}
              />
              {!currentColor && (<span className="error">Обязательное поле</span>)}
            </div>
            <TextField
              type="text"
              placeholder="Название цвета"
              value={currentColorName}
              meta={{
                touched: currentColorNameTouched,
                error: !currentColorName ? 'Обязательное поле' : undefined,
              }}
              onChange={handleChangeColorName}
            />
            <button
              disabled={!currentColor || !currentColorName}
              onClick={handleAddColor}
            >
              Добавить Цвет
            </button>
          </div>
          <div className="row">
            <SelectMui
              value={currentDeletedColor}
              onChange={(e) => {
                setCurrentDeletedColor(e.target.value);
              }}
              error={!currentDeletedColor ? 'Обязательное поле' : undefined}
              helperText={!currentDeletedColor ? 'Обязательное поле' : undefined}
              label="Удаляемый цвет"
              options={colorsOptions}
            />
            <button
              disabled={!currentDeletedColor}
              onClick={handleDeleteColor}
            >
              Удалить цвет
            </button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

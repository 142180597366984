import { createAction } from './common';
import types from '../types';

export const setSelectCategory = createAction(types.SET_SELECT_CATEGORY);
export const setSelectSubCategory = createAction(types.SET_SELECT_SUBCATEGORY);

export const getGoods = createAction(types.GET_SELLER_GOODS);
export const setGoods = createAction(types.SET_SELLER_GOODS);
export const clearGoods = createAction(types.CLEAR_SELLER_GOODS);

export const setGoodsFilter = createAction(types.SET_SELLER_GOODS_FILTERS);
export const delGoodsFilter = createAction(types.DEL_SELLER_GOODS_FILTERS);

import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from '../../simpleComponents/textField';
import { HELPERS } from '../../../helpers';

const PhoneMode = () => {
  return (
    <Field name="phone">
      {({ input, meta }) => (
        <TextField
          {...input}
          meta={meta}
          placeholder="Телефон"
          formatFunc={HELPERS.formatPhone}
        />
      )}
    </Field>
  );
};

export default PhoneMode;

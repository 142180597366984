import React, { useCallback } from 'react';
import { ReactComponent as ZoomIn } from '../../../assets/icons/zoomIn.svg';
import { ReactComponent as ZoomOut } from '../../../assets/icons/zoomOut.svg';
import './index.scss';

export const ScaleButtons = ({ scale, setScale }) => {
  const handleZoomIn = useCallback(() => {
    setScale(scale + 0.1);
  }, [scale, setScale]);

  const handleZoomOut = useCallback(() => {
    setScale(scale - 0.1);
  }, [scale, setScale]);

  return (
    <div className="ScaleButtons">
      <button type="button" onClick={handleZoomIn}>
        <ZoomIn />
      </button>
      <button type="button" onClick={handleZoomOut}>
        <ZoomOut />
      </button>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as CheckMark } from '../../../assets/icons/checkMark.svg';
import { ReactComponent as Arrow } from '../../../assets/filterWrapper/Arrow.svg';
import './index.scss';

const Select = (props) => {
  const { options, defaultOptions, onChange, singleChoice, meta, error } = props;
  const { showSelectedValues, maxValues, label, closeAfterSelection, disabled = false } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const checkIsSelected = (option) => selectedOptions.includes(option.id);

  useEffect(() => setSelectedOptions(
    singleChoice
      ? defaultOptions
        ? [defaultOptions]
        : []
      : defaultOptions || [],
  ), [defaultOptions, singleChoice]);

  const deleteColor = (option) => {
    const filtered = selectedOptions.filter((el) => el !== option);
    setSelectedOptions(filtered);
    if (onChange) onChange(singleChoice ? null : filtered);
    if (closeAfterSelection) setOpen(false);
  };

  const addColor = (option) => {
    const filtered = singleChoice ? [option] : uniq([...selectedOptions, option]);
    setSelectedOptions(filtered);
    if (onChange) onChange(singleChoice ? filtered[0] : filtered);
    if (closeAfterSelection) setOpen(false);
  };

  const clickArrow = useCallback(() => {
    setOpen(!open);
    setTouched(true);
  }, [open]);

  return (
    <div className="Select">
      {
        showSelectedValues && (
          <div className="Select_title">{label}</div>
        )
      }
      {
        showSelectedValues && (
          <div className="topPart">
            <div
              className={cn('showSelectedValues', {
                error: (meta && meta.error) || error,
                disabled,
              })}
            >
              {
                options
                  .filter(checkIsSelected)
                  .map((option) => (
                    <div key={option.id} className="selectedOption">
                      {
                        option.name
                      }
                    </div>
                  ))
              }
              {
                selectedOptions.length === 0 && (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    className="notSelected"
                    onClick={clickArrow}
                  >
                    Не выбрано
                  </div>
                )
              }
            </div>
            <button
              type="button"
              className={cn({
                opened: open,
                disabled,
                error: (meta && meta.error) || error,
              })}
              disabled={disabled}
              onClick={clickArrow}
            >
              <Arrow />
            </button>
          </div>
        )
      }
      {((showSelectedValues && open) || !showSelectedValues) && (
        <div className="valuesContainer">
          {
            options.map((option) => {
              const selected = checkIsSelected(option);
              return (
                <button
                  key={option.id}
                  className="item"
                  type="button"
                  disabled={maxValues && selectedOptions.length === maxValues && !selected}
                  onClick={() => (selected ? deleteColor(option.id) : addColor(option.id))}
                >
                  <div className="checkLabel">
                    {selected && (
                      <CheckMark />
                    )}
                  </div>
                  <div className="name">
                    {option.name}
                  </div>
                </button>
              );
            })
          }
        </div>
      )}
      {meta && meta.error && touched && <span className="errorMessage">{meta.error}</span>}
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  singleChoice: PropTypes.bool,
  showSelectedValues: PropTypes.bool,
  closeAfterSelection: PropTypes.bool,
  defaultOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
};

Select.defaultProps = {
  defaultOptions: [],
  options: [],
  singleChoice: false,
  showSelectedValues: false,
  closeAfterSelection: false,
};

export default Select;

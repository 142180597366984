import types from '../types';

const initialState = {
  mountedAuthComp: false,
  accessToken: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH_DATA:
      return {
        ...action.$payload,
        accessToken: action.$payload.accessToken ?? state.accessToken,
      };
    case types.UNMOUNT_AUTH_COMPONENT:
      return {
        ...state,
        mountedAuthComp: false,
      };
    case types.MOUNT_AUTH_COMPONENT:
      return {
        ...state,
        mountedAuthComp: true,
      };
    case types.CLEAR_AUTH_DATA:
      return {
        mountedAuthComp: false,
        accessToken: null,
      };
    default:
      return state;
  }
};

export default reducer;

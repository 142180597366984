import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from '../../simpleComponents/textField';

export const SendingCode = () => {
  return (
    <>
      <Field name="code">
        {({ input, meta }) => (
          <TextField
            {...input}
            meta={meta}
            placeholder="Код подтверждения"
          />
        )}
      </Field>
      <div className="helpCode">Введите последние 4 цифры номера, который вам позвонит</div>
    </>
  );
};

import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector } from '../index';
import { updateUserData } from '../../../actions/user';
import { Address } from './address';
import { ContactDetails } from './contactDetails';
import { getInitialUserData } from './helper';
import './index.scss';

export const UserData = () => {
  const dsp = useDispatch();
  const user = useSelector(profileSelector);
  const initialValues = getInitialUserData(user || {});

  const onSubmit = (values) => {
    dsp(updateUserData({
      id: user.id,
      body: values,
    }));
  };
  return (
    <div className="UserData">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        // validate={formValidate}
        render={({ handleSubmit, pristine }) => {
          // resetForm = form.reset;
          return (
            <form onSubmit={handleSubmit}>
              <div className="upPart">
                <div className="leftPart">
                  <div className="UserData_title">
                    Контактные данные
                  </div>
                  <ContactDetails />
                </div>
                <div>
                  <div className="UserData_title">
                    Адрес
                  </div>
                  <Address />
                </div>
              </div>
              <div className="saveContainer">
                <button
                  className="save"
                  disabled={pristine}
                  type="submit"
                >
                  Сохранить
                </button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

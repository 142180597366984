import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { ReactComponent as InHome } from '../../../assets/main/inHome.svg';
import { myAddress, myPhone } from '../../../pages/contacts';
import './index.scss';

const Footer = ({ location }) => {
  const isMain = useMemo(() => location.pathname === '/', [location]);
  return (
    <footer className={cn('footer', { footerNone: isMain })}>
      <div className="title">
        <InHome />
      </div>
      <div className="address">
        {myAddress}
      </div>
      <div className="phone">{myPhone}</div>
      <div className="substrate" />
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
  };
};

const ConnectedComponent = connect(mapStateToProps, {})(Footer);

export default ConnectedComponent;

import types from '../types';
import { addSignMore, dimensionsForFavorites, getProductOption } from '../helpers';

const initialState = {
  favorites: null,
  loading: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_GET_FAVORITES:
      return {
        ...state,
        loading: true,
      };
    case types.SET_FAVORITES_LOADING:
      return {
        ...state,
        loading: action.$payload.value,
      };
    case types.SET_FAVORITES:
      return {
        ...state,
        favorites: [...parseFavorites(action.$payload.data, action.$payload.isLocalStorageData)],
      };
    case types.SET_FAVORITE_ITEM:
      return {
        ...state,
        favorites: [...state.favorites || [], action.$payload],
      };
    case types.SET_DEL_FAVORITE_ITEM:
      return {
        ...state,
        favorites: state.favorites.filter((el) => el.productId !== action.$payload.productId),
      };
    case types.DEL_FAVORITES:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

const parseFavorites = (data, isLocalStorageData) => {
  return data.map((el) => {
    const width = isLocalStorageData ? null : getProductOption(el.product.options, 'ширина');
    const length = isLocalStorageData ? null : getProductOption(el.product.options, 'длина');
    const height = isLocalStorageData ? null : getProductOption(el.product.options, 'высота');
    return ({
      id: el.id,
      productId: isLocalStorageData ? el.productId : el.product.id,
      name: isLocalStorageData ? el.name : el.product.name,
      // colors: el.colors,
      dimensions: isLocalStorageData
        ? el.dimensions
        : dimensionsForFavorites({ width, length, height }),
      price: isLocalStorageData ? el.price : addSignMore(el.product.price, 1000000),
      oldPrice: isLocalStorageData ? el.oldPrice : addSignMore(el.product.oldPrice, 1000000),
      mobileImage: isLocalStorageData ? el.mobileImage : (el.product.previews[0]?.url || null),
      desktopImage: isLocalStorageData ? el.desktopImage : (el.product.images[0]?.url || null),
    });
  });
};

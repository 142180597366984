import React, { useEffect } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { orderHistorySelector } from '../../../selectors/order';
import { clearHistoryOrdersList, getHistoryOrdersList } from '../../../actions/order';
import { OrderHistoryItem } from './item';

export const OrdersHistory = () => {
  const list = useSelector(orderHistorySelector);
  const dsp = useDispatch();

  useEffect(() => {
    dsp(getHistoryOrdersList());
    return clearHistoryOrdersList;
  }, [dsp]);

  return (
    <div className="OrdersHistory">
      <div className="OrdersHistory_title">История заказов</div>
      {
        list
          ? list.length !== 0
            ? list.map((el) => <OrderHistoryItem {...el} />)
            : <div className="loadingAndEmptyList">История заказов пуста</div>
          : <div className="loadingAndEmptyList">Загрузка...</div>
      }
    </div>
  );
};

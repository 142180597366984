import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.scss';

export const LoadingContainer = () => {
  return (
    <div className="loadingContainer">
      <CircularProgress color="inherit" />
    </div>
  );
};

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import { adultModalSelector } from '../../selectors/adultModal';
import { setAdultModalValue, setAdultValue } from '../../actions/adultModal';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import './index.scss';

export const AdultModal = () => {
  const { value: adultModal, callback } = useSelector(adultModalSelector);
  const dsp = useDispatch();

  const onClose = useCallback(() => {
    dsp(setAdultModalValue({ value: false, callback: null }));
    dsp(setAdultValue({ value: null }));
  }, [dsp]);

  const handleYesValue = useCallback(() => {
    callback?.();
    dsp(setAdultValue({ value: true }));
    dsp(setAdultModalValue({ value: false, callback: null }));
  }, [dsp, callback]);

  const handleNoValue = useCallback(() => {
    dsp(setAdultValue({ value: false }));
    dsp(setAdultModalValue({ value: false, callback: null }));
  }, [dsp]);

  return (
    <Modal
      className="AdultModal"
      open={adultModal}
      onClose={onClose}
    >
      <div className="AdultModal__content">
        <button
          type="button"
          className="closeModalBtn"
          onClick={onClose}
        >
          <Cross />
        </button>
        <div className="title">Вам есть 18 лет?</div>
        <div className="buttons">
          <button onClick={handleYesValue}>Да</button>
          <button onClick={handleNoValue}>Нет</button>
        </div>
      </div>
    </Modal>
  );
};

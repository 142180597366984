import { all, takeLatest } from 'redux-saga/effects';
import types from '../types';
import { yandexSearchSaga, yandexSearchCoordsSaga } from './yandex';
import {
  addCategoriesSaga, addColorSaga, addSubCategoriesSaga, changeUserRoleSaga, delColorSaga,
  deleteCategoriesSaga, deleteSubCategorySaga,
  getCategoriesSaga, getColorsSaga,
  getCurrentUserSaga, getRolesSaga, getUsersSaga,
  // sendCodeSaga,
  // sendPhoneSaga,
  logoutSaga, sendCodeSaga, sendPhoneSaga, updateUserSaga,
} from './auth';
import {
  createProductSaga,
  // getCatalogueFiltersSaga,
  getListSaga,
  likeItemSaga,
  removeProductSaga,
  updateProductSaga,
} from './catalogue';
import {
  changeBasketListSaga,
  addBasketItemSaga,
  getBasketListSaga,
  deleteBasketItemSaga,
  deleteBasketListSaga,
} from './basket';
import { getFeedbacksSaga, getGoodDataSaga, createFeedbacksSaga } from './goodPage';
import { getFavoritesSaga, removeAllFavoritesSaga, removeFavoriteItemSaga, syncFavoritesSaga } from './favorites';
import { addOptionsTypeSaga, getOptionsTypesSaga } from './optionsTypes';
import { getOrderListSaga, sendOrderListSaga } from './order';
import { getListSalesmanSaga } from './salesman';
import {
  addCommonBannerSaga, addSellerBannerSaga,
  getCommonBannersSaga, getSellerBannerSaga,
  removeCommonBannerSaga, removeSellerBannerSaga,
  updateCommonBannerSaga,
} from './banner';
import {
  getDeliveryPriceSaga,
  // updateDeliveryPriceSaga,
} from './delivery';

function* rootSaga() {
  yield all([
    takeLatest(types.YANDEX_SEARCH_ADDRESS, yandexSearchSaga),
    takeLatest(types.SEARCH_COORDS, yandexSearchCoordsSaga),
    // takeLatest( types.SEND_PHONE_LOGIN, sendPhoneSaga),
    // takeLatest( types.SEND_CODE_LOGIN, sendCodeSaga),
    takeLatest(types.LOGOUT_USER, logoutSaga),
    takeLatest(types.GET_USER, getCurrentUserSaga),

    takeLatest(types.GET_CATALOGUE_LIST, getListSaga),
    takeLatest(types.LIKE_CATALOGUE_ITEM, likeItemSaga),
    // takeLatest(types.GET_CATALOGUE_FILTERS, getCatalogueFiltersSaga),
    takeLatest(types.FETCH_GET_BASKET, getBasketListSaga),
    takeLatest(types.DELETE_BASKET_ITEM, deleteBasketItemSaga),
    takeLatest(types.DEL_BASKET, deleteBasketListSaga),
    takeLatest(types.FETCH_CHANGE_BASKET, changeBasketListSaga),
    takeLatest(types.FETCH_ADD_BASKET_ITEM, addBasketItemSaga),
    takeLatest(types.FETCH_GET_GOOD_DATA, getGoodDataSaga),
    takeLatest(types.FETCH_GET_FEEDBACKS, getFeedbacksSaga),
    takeLatest(types.CREATE_FEEDBACKS, createFeedbacksSaga),
    takeLatest(types.FETCH_GET_FAVORITES, getFavoritesSaga),
    takeLatest(types.FETCH_SYNC_FAVORITES, syncFavoritesSaga),
    takeLatest(types.DEL_FAVORITE_ITEM, removeFavoriteItemSaga),
    takeLatest(types.DEL_ALL_FAVORITES, removeAllFavoritesSaga),
    takeLatest(types.SEND_PHONE_LOGIN, sendPhoneSaga),
    takeLatest(types.FETCH_CREATE_PRODUCT, createProductSaga),
    takeLatest(types.FETCH_UPDATE_PRODUCT, updateProductSaga),
    takeLatest(types.FETCH_REMOVE_PRODUCT, removeProductSaga),
    takeLatest(types.SEND_CODE_LOGIN, sendCodeSaga),
    takeLatest(types.GET_CATEGORIES, getCategoriesSaga),
    takeLatest(types.ADD_CATEGORIES, addCategoriesSaga),
    takeLatest(types.ADD_SUBCATEGORIES, addSubCategoriesSaga),
    takeLatest(types.DEL_SUBCATEGORIES, deleteSubCategorySaga),
    takeLatest(types.GET_SELLER_GOODS, getListSalesmanSaga),
    takeLatest(types.DELETE_CATEGORIES, deleteCategoriesSaga),
    takeLatest(types.GET_COLORS, getColorsSaga),
    takeLatest(types.DEL_COLOR, delColorSaga),
    takeLatest(types.ADD_COLOR, addColorSaga),
    takeLatest(types.GET_OPTIONS_TYPES, getOptionsTypesSaga),
    takeLatest(types.ADD_OPTIONS_TYPES, addOptionsTypeSaga),
    takeLatest(types.SEND_ORDER_LIST, sendOrderListSaga),
    takeLatest(types.GET_HISTORY_ORDERS_LIST, getOrderListSaga),
    takeLatest(types.GET_ROLES, getRolesSaga),
    takeLatest(types.GET_USERS, getUsersSaga),
    takeLatest(types.CHANGE_ROLE_USER, changeUserRoleSaga),
    takeLatest(types.GET_COMMON_BANNERS, getCommonBannersSaga),
    takeLatest(types.ADD_COMMON_BANNER, addCommonBannerSaga),
    takeLatest(types.ADD_SELLER_BANNER, addSellerBannerSaga),
    takeLatest(types.REMOVE_COMMON_BANNER, removeCommonBannerSaga),
    takeLatest(types.UPDATE_COMMON_BANNER, updateCommonBannerSaga),
    takeLatest(types.GET_SELLER_BANNERS, getSellerBannerSaga),
    takeLatest(types.REMOVE_SELLER_BANNER, removeSellerBannerSaga),
    takeLatest(types.UPDATE_USER_DATA, updateUserSaga),
    takeLatest(types.GET_DELIVERY_PRICE, getDeliveryPriceSaga),
    // takeLatest(types.UPDATE_DELIVERY_PRICE, updateDeliveryPriceSaga),
  ]);
}

export default rootSaga;

import { put } from 'redux-saga/effects';
import API from '../api';
import {
  setAddedCommonBanner, setAddedFlagCommonBanner, setAddedSellerBanner,
  setCommonBanners,
  setRemoveCommonBanner, setSellerBanners,
  setUpdatedCommonBanner,
} from '../actions/banner';
import { setNotification } from '../actions/notification';

export function* getCommonBannersSaga() {
  try {
    const json = yield API.rest.banner.get();
    yield put(setCommonBanners(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* addCommonBannerSaga({ $payload }) {
  try {
    const json = yield API.rest.banner.add($payload);
    yield put(setAddedCommonBanner(json));
    yield put(setAddedFlagCommonBanner({ value: true }));
    yield put(setNotification({
      type: 'success',
      message: 'Вы успешно добавили баннер',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка добавления баннера',
    }));
  }
}

export function* removeCommonBannerSaga({ $payload }) {
  try {
    const { id } = $payload;
    yield API.rest.banner.remove({ id });
    yield put(setRemoveCommonBanner({ id }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка удаления баннера',
    }));
  }
}

export function* updateCommonBannerSaga({ $payload }) {
  try {
    const { id, ...body } = $payload;
    const json = yield API.rest.banner.update(id, body);
    yield put(setUpdatedCommonBanner(json));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка обновления баннера',
    }));
  }
}

export function* addSellerBannerSaga({ $payload }) {
  try {
    const json = yield API.rest.banner.addSellerBanner($payload);
    yield put(setAddedSellerBanner(json));
    yield put(setNotification({
      type: 'success',
      message: 'Вы успешно добавили баннер',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка добавления баннера',
    }));
  }
}

export function* removeSellerBannerSaga({ $payload }) {
  try {
    yield API.rest.banner.removeSellerBanner($payload);
    yield put(setNotification({
      type: 'success',
      message: 'Вы успешно удалили баннер',
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка удаления баннера',
    }));
  }
}

export function* getSellerBannerSaga({ $payload }) {
  try {
    const json = yield API.rest.banner.getSellerBanner($payload);
    yield put(setSellerBanners((json.link && json.url) ? [{ ...json, id: 1 }] : []));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка получения баннера',
    }));
  }
}

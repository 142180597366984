import React, { useCallback } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import './index.scss';
import { useDispatch } from 'react-redux';
import { OrderItem } from '../../../order/orderItem';
import { history } from '../../../../store';
import { routes } from '../../../../routes';
import { setOrderList } from '../../../../actions/order';

moment.locale('ru');

export const OrderHistoryItem = ({ created_at, products }) => {
  const date = moment(created_at).format('DD MMMM YYYY, HH:MM');
  const dsp = useDispatch();

  const checkout = useCallback(() => {
    dsp(setOrderList(
      products
        // .filter((item) => selectedIds.includes(item.id))
        .map((item) => ({
          id: item.id,
          name: item.name,
          count: item.pivot.count,
          price: item.price,
          mobileImage: null, // @TODO добавить поле на беке
          desktopImage: item.images[0]?.url || null,
          dimensions: '',
        })),
    ));
    history.push(routes.order());
  }, [products, dsp]);

  return (
    <div className="OrderHistoryItem">
      <div className="dateAndTime">
        {date}
      </div>
      <div className="goods">
        {
          products.map((el) => (
            <OrderItem
              key={el.id}
              id={el.id}
              name={el.name}
              dimensions=""
              desktopImage={el.images[0]?.url || null}
              mobileImage={el.previews[0]?.url || null}
            />
          ))
        }
      </div>
      <button className="reopenOrder" onClick={checkout}>Повторить заказ</button>
    </div>
  );
};

import { FieldArray } from 'react-final-form-arrays';
import React from 'react';
import { Field } from 'react-final-form';
import { PhotoField } from '../photoField';

// name images[1][url] or previews[1][url]
export const PhotoList = ({ name, needWidth, needHeight, label }) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.map((name) => (
            <Field
              name={`${name}[url]`}
              component={PhotoField}
              label={`${label}`}
              needWidth={needWidth}
              needHeight={needHeight}
            />
          ))}
        </>
      )}
    </FieldArray>
  );
};

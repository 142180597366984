import { Field } from 'react-final-form';
import React from 'react';
import { TextField } from '../../simpleComponents/textField';

const AuthModeFields = () => {
  return (
    <>
      <Field name="email">
        {({ input }) => (
          <TextField
            {...input}
            placeholder="Электронная почта"
          />
        )}
      </Field>
      <Field name="password">
        {({ input }) => (
          <TextField
            {...input}
            type="password"
            placeholder="Пароль"
          />
        )}
      </Field>
    </>
  );
};

export default AuthModeFields;

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as CheckboxMark } from '../../../assets/icons/checkMark.svg';
import './index.scss';

const CheckboxComponent = ({ value, disabled, children, handleChange }) => {
  const [currentValue, setCurrentValue] = useState(false);
  const [currentDisabled, setCurrentDisabled] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    setCurrentDisabled(disabled);
  }, [disabled]);

  const getClickHandler = () => {
    if (!disabled && handleChange) {
      handleChange(!currentValue);
    }
  };

  return (
    <div className="CheckboxComponent">
        <div
          className={cn('checkbox', {
            disabled: currentDisabled,
            checked: currentValue,
          })}
          onClick={getClickHandler}
        >
          <CheckboxMark className="checkboxMark" />
        </div>
      {!!children && (
        <span
          className={cn('text', {
            disabled: currentDisabled,
          })}
          onClick={getClickHandler}
        >
            {children}
        </span>
      )}
    </div>
  );
};
export default CheckboxComponent;

import fp from 'lodash/fp';
import types from '../types';

const initialState = {
  address: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.YANDEX_SET_ADDRESS:
      return {
        ...state,
        address: parseAddress(action.$payload),
      };
    default:
      return state;
  }
};

export default reducer;

const parseAddress = (data) => fp.get('response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.Components', data);

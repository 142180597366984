import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import yandexReducer from './yandex';
import catalogueReducer from './catalogue';
import authReducer from './auth';
import basketReducer from './basket';
import goodPageReducer from './goodPage';
import favoritesReducer from './favorites';
import profileReducer from './profile';
import optionsTypesReducer from './optionsTypes';
import orderReducer from './order';
import notificationReducer from './notification';
import salesmanReducer from './salesman';
import bannerReducer from './banner';
import deliveryPriceReducer from './delivery';
import adultModalReducer from './adultModal';

export default (history) => combineReducers({
  router: connectRouter(history),
  yandex: yandexReducer,
  catalogue: catalogueReducer,
  goodPage: goodPageReducer,
  auth: authReducer,
  basket: basketReducer,
  favorites: favoritesReducer,
  profile: profileReducer,
  optionsTypes: optionsTypesReducer,
  order: orderReducer,
  notification: notificationReducer,
  salesman: salesmanReducer,
  banner: bannerReducer,
  deliveryPrice: deliveryPriceReducer,
  adultModal: adultModalReducer,
});

import { createAction } from './common';
import types from '../types';

export const getUser = createAction(types.GET_USER);
export const setUser = createAction(types.SET_USER);
export const clearUserData = createAction(types.CLEAR_USER_DATA);
export const logoutUser = createAction(types.LOGOUT_USER);
export const setIsLoadingAuth = createAction(types.SET_IS_LOADING_AUTH);

export const getCategories = createAction(types.GET_CATEGORIES);
export const setCategories = createAction(types.SET_CATEGORIES);
export const clearCategories = createAction(types.CLEAR_CATEGORIES);
export const addCategories = createAction(types.ADD_CATEGORIES);
export const deleteCategories = createAction(types.DELETE_CATEGORIES);

export const addSubCategories = createAction(types.ADD_SUBCATEGORIES);
export const delSubCategories = createAction(types.DEL_SUBCATEGORIES);
export const getSubCategories = createAction(types.GET_SUBCATEGORIES);
export const setSubCategories = createAction(types.SET_SUBCATEGORIES);

export const getColors = createAction(types.GET_COLORS);
export const setColors = createAction(types.SET_COLORS);
export const clearColors = createAction(types.CLEAR_COLORS);
export const addColor = createAction(types.ADD_COLOR);
export const delColor = createAction(types.DEL_COLOR);

export const getRoles = createAction(types.GET_ROLES);
export const setRoles = createAction(types.SET_ROLES);

export const getUsers = createAction(types.GET_USERS);
export const setUsers = createAction(types.SET_USERS);
export const clearUsers = createAction(types.CLEAR_USERS);
export const changeRoleUser = createAction(types.CHANGE_ROLE_USER);
export const setRoleUser = createAction(types.SET_ROLE_USER);
export const setChangingUserRole = createAction(types.SET_CHANGING_USER_ROLE);

export const updateUserData = createAction(types.UPDATE_USER_DATA);

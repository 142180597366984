import { createAction } from './common';
import types from '../types';

export const getCommonBanners = createAction(types.GET_COMMON_BANNERS);
export const setCommonBanners = createAction(types.SET_COMMON_BANNERS);
export const clearCommonBanners = createAction(types.CLEAR_COMMON_BANNERS);
export const addCommonBanner = createAction(types.ADD_COMMON_BANNER);
export const setAddedCommonBanner = createAction(types.SET_ADDED_COMMON_BANNERS);
export const setAddedFlagCommonBanner = createAction(types.SET_ADDED_COMMON_BANNER_FLAG);
export const removeCommonBanner = createAction(types.REMOVE_COMMON_BANNER);
export const setRemoveCommonBanner = createAction(types.SET_REMOVED_COMMON_BANNER);
export const updateCommonBanner = createAction(types.UPDATE_COMMON_BANNER);
export const setUpdatedCommonBanner = createAction(types.SET_UPDATED_COMMON_BANNER);

export const getSellerBanners = createAction(types.GET_SELLER_BANNERS);
export const setSellerBanners = createAction(types.SET_SELLER_BANNERS);
export const clearSellerBanners = createAction(types.CLEAR_SELLER_BANNERS);
export const addSellerBanner = createAction(types.ADD_SELLER_BANNER);
export const removeSellerBanner = createAction(types.REMOVE_SELLER_BANNER);
export const setAddedSellerBanner = createAction(types.SET_ADDED_SELLER_BANNER);

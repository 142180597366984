import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store, history } from './store';
import { APIConnector } from './api';
import Layout from './components/layout';
import { incerceptor } from './api/rest';

incerceptor(store);

function App() {
  return (
    <Provider store={store}>
      <APIConnector>
        <ConnectedRouter history={history}>
          <Layout />
        </ConnectedRouter>
      </APIConnector>
    </Provider>
  );
}

export default App;

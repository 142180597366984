import types from '../types';

const initialState = {
  commonBanners: null,
  sellerBanners: null,
  added: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMMON_BANNERS:
      return {
        ...state,
        commonBanners: action.$payload,
      };
    case types.SET_ADDED_COMMON_BANNERS:
      return {
        ...state,
        commonBanners: [...state.commonBanners, action.$payload],
      };
    case types.SET_REMOVED_COMMON_BANNER:
      return {
        ...state,
        commonBanners: state.commonBanners.filter((el) => action.$payload.id !== el.id),
      };
    case types.SET_ADDED_COMMON_BANNER_FLAG:
      return {
        ...state,
        added: action.$payload.value,
      };
    case types.SET_SELLER_BANNERS:
      return {
        ...state,
        sellerBanners: action.$payload,
      };
    case types.SET_ADDED_SELLER_BANNER:
      return {
        ...state,
        sellerBanners: [...state.sellerBanners || [], action.$payload],
      };
    case types.CLEAR_COMMON_BANNERS:
      return {
        ...state,
        commonBanners: null,
      };
    case types.CLEAR_SELLER_BANNERS:
      return {
        ...state,
        sellerBanners: null,
      };
    default:
      return state;
  }
};

export default reducer;

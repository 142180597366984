import { createAction } from './common';
import types from '../types';

export const getGoodData = createAction(types.FETCH_GET_GOOD_DATA);
export const createProduct = createAction(types.FETCH_CREATE_PRODUCT);
export const getFeedbacks = createAction(types.FETCH_GET_FEEDBACKS);
export const createFeedbacks = createAction(types.CREATE_FEEDBACKS);
export const setFeedbacks = createAction(types.SET_FEEDBACKS);
export const setGoodData = createAction(types.SET_GOOD_DATA);
export const setGoodLoadingStatus = createAction(types.SET_GOOD_LOADING_STATUS);
export const setLoadingFeedbacks = createAction(types.SET_LOADING_FEEDBACKS);
export const delGoodData = createAction(types.DEL_GOOD_DATA);
export const setPrevUrl = createAction(types.SET_PREV_URL);

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { TextField } from '../../../../components/simpleComponents/textField';
import { addSubCategories, delSubCategories } from '../../../../actions/user';
import { SelectMui } from '../../../../components/simpleComponents/selectMui';

export const Subcategories = ({ categories, subcategories }) => {
  const [currentCategoryName, setCurrentCategoryName] = useState(null);
  const [currentRootCategory, setCurrentRootCategory] = useState(null);
  const [subcategoryToBeDeleted, setSubcategoryToBeDeleted] = useState(null);
  const [fileForAdd, setFileForAdd] = useState(null); // 180x143
  const dsp = useDispatch();

  const addCategory = useCallback(() => {
    dsp(addSubCategories({
      values: {
        name: currentCategoryName,
        parent_id: currentRootCategory,
        ...fileForAdd ? { image_url: fileForAdd } : {},
      },
      onFinish: () => {
        setCurrentRootCategory(null);
        setCurrentCategoryName(null);
        setFileForAdd(null);
      },
    }));
  }, [currentCategoryName, currentRootCategory, dsp, fileForAdd]);

  const deleteSubCategory = useCallback(() => {
    dsp(delSubCategories({
      id: subcategoryToBeDeleted,
      onFinish: () => {
        setSubcategoryToBeDeleted(null);
      },
    }));
  }, [dsp, subcategoryToBeDeleted]);

  const categoriesOptions = useMemo(
    () => (categories ? categories.map((el) => ({ label: el.name, value: el.id })) : []),
    [categories],
  );

  const subcategoriesOptions = useMemo(
    () => (subcategories ? subcategories.map((el) => ({ label: el.name, value: el.id })) : []),
    [subcategories],
  );

  const onSelectFileForAdd = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setFileForAdd(reader.result?.toString() || '');
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        Подкатегории
      </AccordionSummary>
      <AccordionDetails>
        <div className="SubCategoriesPanel">
          <div className="editCategory">
            <div>Создать подкатегорию</div>
            <TextField
              placeholder="Название новой подкатегории"
              value={currentCategoryName}
              error={!currentCategoryName ? 'Обязательное поле' : undefined}
              onChange={(e) => {
                setCurrentCategoryName(e.target.value);
              }}
            />
            <input
              // value={file}
              type="file"
              accept="image/png"
              onChange={onSelectFileForAdd}
            />
            {!fileForAdd && <span className="error">Обязательное поле</span>}
            <SelectMui
              className="rootCategory"
              label="Выберите родительскую категорию"
              value={currentRootCategory}
              error={!currentRootCategory}
              helperText={!currentRootCategory ? 'Обязательное поле' : undefined}
              onChange={(e) => {
                setCurrentRootCategory(e.target.value);
              }}
              options={categoriesOptions}
            />
            <button
              type="button"
              disabled={!currentCategoryName || !currentRootCategory}
              onClick={addCategory}
            >
              Добавить подкатегорию
            </button>
            <div>Удалить подкатегорию</div>
            <SelectMui
              className="rootCategory"
              label="Выберите подкатегорию"
              value={subcategoryToBeDeleted}
              error={!subcategoryToBeDeleted}
              helperText={!subcategoryToBeDeleted ? 'Обязательное поле' : undefined}
              onChange={(e) => {
                setSubcategoryToBeDeleted(e.target.value);
              }}
              options={subcategoriesOptions}
            />
            <button
              type="button"
              disabled={!subcategoryToBeDeleted}
              onClick={deleteSubCategory}
            >
              Удалить подкатегорию
            </button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

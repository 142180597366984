import types from '../types';

const initialState = {
  optionsTypes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OPTIONS_TYPES:
      return {
        ...state,
        optionsTypes: [...action.$payload],
      };
    case types.CLEAR_OPTIONS_TYPES:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { addCategories, deleteCategories } from '../../../../actions/user';
import { SelectMui } from '../../../../components/simpleComponents/selectMui';
import { TextField } from '../../../../components/simpleComponents/textField';
import Checkbox from '../../../../components/simpleComponents/checkboxComponent';

export const CategoriesPanel = ({ categories }) => {
  const [currentDeletedCategory, setCurrentDeletedCategory] = useState(null);
  const [currentCategoryName, setCurrentCategoryName] = useState(null);
  const [isTobacco, setIsTobacco] = useState(false);
  const [file, setFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const dsp = useDispatch();

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setFile(reader.result?.toString() || '');
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const addCategory = () => {
    dsp(addCategories({
      values: {
        name: currentCategoryName,
        is_tobacco: isTobacco,
        percent,
        ...file ? { image_url: file } : {},
      },
      onFinish: () => {
        setCurrentCategoryName(null);
        setFile(null);
        setIsTobacco(false);
      },
    }));
  };

  const deleteCategory = () => {
    dsp(deleteCategories({
      value: currentDeletedCategory,
      onFinish: () => {
        setCurrentDeletedCategory(null);
      },
    }));
  };

  const categoriesOptions = useMemo( // TODO сделать рефакторинг, чтоб это ве делала ф-я
    () => (categories ? categories.map((el) => ({ label: el.name, value: el.id })) : []),
    [categories],
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        Категории
      </AccordionSummary>
      <AccordionDetails>
        <div className="CategoriesPanel">
          <div className="editCategory">
            <div>Добавить категорию</div>
            <TextField
              placeholder="Название новой категории"
              value={currentCategoryName}
              error={!currentCategoryName ? 'Обязательное поле' : undefined}
              onChange={(e) => {
                setCurrentCategoryName(e.target.value);
              }}
            />
            <TextField
              placeholder="Процент, который будет добавляться к стоимости товара"
              value={percent}
              error={!percent.toString() ? 'Обязательное поле' : undefined}
              onChange={(e) => {
                setPercent(e.target.value === '' ? '' : parseInt(e.target.value.replace(/[^\d]/g, ''), 10));
              }}
            />
            <Checkbox
              value={isTobacco}
              handleChange={setIsTobacco}
            >
              Эта категория - табачная продукция
            </Checkbox>
            <input
              // value={file}
              type="file"
              accept="image/png"
              onChange={onSelectFile}
            />
            {!file && <span className="error">Обязательное поле</span>}
            <button
              type="button"
              disabled={!currentCategoryName} // || !file
              onClick={addCategory}
            >
              Добавить категорию
            </button>
          </div>
          <div className="editCategory">
            <SelectMui
              label="Категория"
              value={currentDeletedCategory}
              error={!currentDeletedCategory}
              helperText={!currentDeletedCategory ? 'Обязательное поле' : undefined}
              onChange={(e) => {
                setCurrentDeletedCategory(e.target.value);
              }}
              options={categoriesOptions}
            />
            <button
              type="button"
              disabled={!currentDeletedCategory}
              onClick={deleteCategory}
            >
              Удалить категорию
            </button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

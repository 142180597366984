import React from 'react';
import BreadCrumbs from '../../components/breadCrumbs';
import { routes } from '../../routes';
import { ReactComponent as Book } from '../../assets/contacts/Book.svg';
import { ReactComponent as Call } from '../../assets/contacts/Call.svg';
import './index.scss';

const breadcrumbsList = [
  {
    id: 1,
    link: routes.catalogue(),
    title: 'Главная',
  },
  {
    id: 2,
    link: routes.contacts(),
    title: 'Контакты',
  },
];

export const myAddress = '442960, Пензенская обл., г. Заречный, проезд Школьный 2-й';
export const myPhone = '+7 (999) 999 - 99 - 99';

const Contacts = () => {
  return (
    <div className="Contacts">
      <BreadCrumbs list={breadcrumbsList} />
      <h2>Контакты</h2>
      <div className="twoParts">
        <div className="Contacts_Content">
          <div className="Address">
            <Book />
            <div className="Address_Container">
              <div className="name">Адрес</div>
              <div className="index">{myAddress}</div>
            </div>
          </div>
          <div className="telephone">
            <Call />
            <div className="telephone_container">
              <div className="name">
                Телефон
              </div>
              <div className="number">
                <a
                  className="number_index"
                  href={`tel:${myPhone.replaceAll(/[() -]/g, '')}`}
                >
                  {myPhone}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Map_Container">
          <div
            style={{ position: 'relative', overflow: 'hidden' }}
          >
            <a
              href="https://yandex.ru/maps/11098/zarechny/?utm_medium=mapframe&utm_source=maps"
              style={{
                color: '#eee', fontSize: '12px', position: 'absolute', top: '0px',
              }}
            >
              Заречный
            </a>
            <a
              href="https://yandex.ru/maps/11098/zarechny/house/2_y_shkolny_proyezd_4/YEwYdAdjTEYGQFtpfX14eXRgYA==/?ll=45.202641%2C53.199125&utm_medium=mapframe&utm_source=maps&z=16.63"
              style={{
                color: '#eee', fontSize: '12px', position: 'absolute', top: '14px',
              }}
            >
              2-й Школьный проезд, 4 — Яндекс Карты
            </a>
            <iframe
              title="map"
              src="https://yandex.ru/map-widget/v1/?ll=45.202641%2C53.199125&mode=whatshere&whatshere%5Bpoint%5D=45.203481%2C53.198841&whatshere%5Bzoom%5D=17&z=16.63"
              frameBorder="1"
              allowFullScreen="true"
              style={{ position: 'relative' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import React from 'react';
import { isNil } from 'lodash';
import { TextArea } from '../../simpleComponents/textArea';

const TextAreaFF = (props) => {
  const { input: { name, onChange, value }, label, placeholder, maxlength, meta, ...rest } = props;
  return (
    <TextArea
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      label={label}
      meta={meta}
      rows="10"
      maxlength={!isNil(maxlength) ? maxlength : 255}
      {...rest}
    />
  );
};

export default TextAreaFF;

import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from '../../../../components/simpleComponents/textField';
import validators from '../../../../helpers/form-validators/validators-lodash';

export const Address = ({ isOrderFields = false }) => {
  return (
    <div className="ProfileAndOrderFields">
      <div className="fields">
        <Field
          name="address.region"
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Область"
            />
          )}
        </Field>
        <Field
          name="address.city"
          validate={isOrderFields ? validators.required : undefined}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Населенный пункт"
            />
          )}
        </Field>
        <Field
          name="address.street"
          validate={isOrderFields ? validators.required : undefined}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              meta={meta}
              placeholder="Улица"
            />
          )}
        </Field>
        <div className="double">
          <Field
            name="address.house"
            validate={isOrderFields ? validators.required : undefined}
          >
            {({ input, meta }) => (
              <TextField
                {...input}
                meta={meta}
                placeholder="Номер дома"
              />
            )}
          </Field>
          <Field
            name="address.flat"
          >
            {({ input, meta }) => (
              <TextField
                {...input}
                meta={meta}
                placeholder="Квартира"
              />
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import Select from '../../simpleComponents/select';
// import './index.scss';

const SelectFF = (props) => {
  const {
    label, input: { onChange, value },
    options, singleChoice, closeAfterSelection, meta, children, ...rest
  } = props;

  return (
    <div className="SelectFF">
      <Select
        {...rest}
        defaultOptions={value}
        options={options}
        label={label}
        onChange={onChange}
        singleChoice={singleChoice}
        closeAfterSelection={closeAfterSelection}
        meta={meta}
        showSelectedValues
      />
    </div>
  );
};

export default SelectFF;

import axios from 'axios';
import qs from 'qs';
import {
  checkContentType,
  CONTENT_TYPES, getBaseUrl,
  getContentDispositionFilename,
  isHasBody,
  isNeedVersionApi,
  saveAsFile,
} from '../helpers/http';
import Errors from './errors';
import {
  AB_TEST_LOGIN,
  AB_TEST_PASSWORD, DOMAIN,
  YANDEX_GEOCODE_URL,
  YANDEX_TOCKEN,
} from './constants';
import { getFailPaymentUrl, getSuccessPaymentUrl } from '../helpers';
import { clearAuthData } from '../actions/auth';

const VERSION_API = '';

export const incerceptor = (store) => {
  axios.interceptors.response.use(null, async (error) => {
    // const originalRequest = error.config;
    if (error?.response?.status === 401) { //  && originalRequest.url === '/oauth/oauth2'
      store.dispatch(clearAuthData());
      localStorage.clear();
    }
    return Promise.reject(error);
  });
};

const resolve = (params) => {
  const { response, saveAs } = params;
  const isContentType = checkContentType(response);
  const response_json = isContentType(CONTENT_TYPES.json) && response.data;
  switch (response?.status) {
    case 200:
    case 201:
    case 202:
      return (
        saveAs
          ? saveAsFile(response, getContentDispositionFilename(response, saveAs))
          : (response_json || response)
      );
    case 204:
      return null;
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown response status: ${response?.status}\n`, response);
      return null;
  }
};

const reject = (response) => {
  if (response?.request?.responseType === 'blob' && response?.data instanceof Blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        response.data = JSON.parse(reader.result);
        resolve(Promise.reject(response));
      };

      reader.onerror = () => {
        reject(response);
      };

      reader.readAsText(response.data);
    });
  }
  switch (response?.status) {
    case 401:
      throw new Errors.NotAuthorized(response);
    case 403:
      throw new Errors.Forbidden(response);
    case 400:
      throw new Errors.BadRequest(response);
    case 500:
      throw new Errors.Backend(response);
    default:
      throw new Errors.Unknown(response, response.data);
  }
};

const options = ({
  accessToken,
  // sessionToken,
  // access_token,
  // accept,
  // basic,
  body,
  content_type = CONTENT_TYPES.json,
  headers = {},
  method = 'POST',
  onDownloadProgress,
  onUploadProgress,
  path = '',
  query = {},
  responseType,
  token_type = 'Bearer',
  url = (() => { throw new Error('Url is not specified'); })(),
}) => {
  const hasBody = isHasBody(method);
  const data = body && hasBody && (
    content_type === CONTENT_TYPES.json
      ? JSON.stringify(body)
      : content_type === CONTENT_TYPES.form
        ? qs.stringify(body)
        : body
  );

  const url_path = path ? `/${path}` : '';
  const url_query = qs.stringify({ ...query, ...!hasBody && body });
  // , url.includes('catalogue') ? { arrayFormat: 'repeat' } : { arrayFormat: 'comma' }
  const url_version_api = isNeedVersionApi(url) ? '' : VERSION_API;
  const url_base = getBaseUrl(url);
  const url_request = [DOMAIN, url_base, url_version_api, url, url_path, url_query ? '?' : '', url_query].join('');
  return {
    method,
    ...data && { data },
    // ...!isNeedToken && {auth: basic},
    // auth: BASIC,
    headers: {
      // Accept: accept ? accept : content_type ? content_type : CONTENT_TYPES.json,
      ...hasBody && { 'Content-Type': content_type },
      ...accessToken && { Authorization: `${token_type} ${accessToken}` },
      ...!!accessToken && { Token: accessToken }, // client-token
      // ...!!sessionToken && { 'X-Session-Token': sessionToken }, // session-token
      ...headers,
    },
    responseType,
    onUploadProgress,
    onDownloadProgress,
    url: url_request,
  };
};

const request = ({
  parent,
  // eslint-disable-next-line no-unused-vars
  reconnect = reject,
  saveAs,
  ...props
}) => (
  axios(options(props))
    .then(
      (response) => resolve({ response, saveAs }),
      parent
        ? reject
        : ({ response }) => reject(response),
    )
);

const connect = (props) => {
  const { reconnect } = props;
  const {
    accessToken,
  } = props.auth || {};
  const method = ({ name, ...opts }) => (args) => request({
    accessToken, ...opts, ...args, reconnect, method: name,
  });

  const get = method({ name: 'GET' });
  const post = method({ name: 'POST' });
  const put = method({ name: 'PUT' });
  const patch = method({ name: 'PATCH', content_type: CONTENT_TYPES.json });
  const remove = method({ name: 'DELETE' });

  const crud = (url) => ({
    create: (body) => post({ url, body }),
    item: (id) => get({ url, path: id }),
    list: (query = {}) => get({ url, query }),
    patch: ({ id, body }) => patch({ url, path: id, body }),
    remove: (id) => remove({ url, path: id }),
    update: ({ id, body }) => put({ url, path: id, body }),
    download: (id, onDownloadProgress) => get({
      url,
      path: id,
      responseType: 'blob',
      onDownloadProgress,
      saveAs: `download-${id}.file`,
    }),
    upload: (files, onUploadProgress) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            content_type: CONTENT_TYPES.formdata,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
  });

  const auth = (url) => ({
    getProfile: () => (
      get({
        url,
      })
    ),
    update: ({ body = {} }) => post({ url, body }),
  });

  const user = (url) => ({
    list: (query = {}) => get({ url, query }),
    update: ({ id, body = {} }) => put({ url, body, path: id }),
    changeRole: ({ id, body = {} }) => patch({ url: `${url}/${id}/role`, body }),
  });

  const banner = (url) => ({
    get: (query = {}) => get({ url, query }),
    add: (body = {}) => post({ url, body }),
    addSellerBanner: (body = {}) => post({ url: '/auth/banner/save', body }),
    getSellerBanner: (id) => get({ url: `/user/${id}/banner` }),
    removeSellerBanner: (body = {}) => remove({ url: '/auth/banner/remove', body }),
    remove: ({ id }) => remove({ url, path: id }),
    update: ({ id, body = {} }) => put({ url, path: id, body }),
  });

  const login = (url) => ({
    getCsrfCookie: () => (
      get({
        url: '/sanctum/csrf-cookie',
      })
    ),
    sendPhone: (body) => (
      post({
        url: `${url}/phone`,
        body,
      })
    ),
    sendCode: (body) => (
      post({
        url: `${url}/code`,
        body,
      })
    ),
  });

  const review = (url) => ({
    list: (query = {}) => get({ url, query }),
    add: (body = {}) => post({ url, body }),
    del: ({ id }) => remove({ url, path: id }),
    item: (body) => get({ url, body }),
  });

  const favorites = (url) => ({
    list: (query = {}) => get({ url, query }),
    add: (body = {}) => post({ url, body }),
    del: ({ id }) => remove({ url, path: id }),
    delAll: () => post({ url: `${url}/truncate` }),
    sync: (body) => post({ url: `${url}/sync`, body }),
  });

  const order = (url) => ({
    list: (query = {}) => get({ url, query }),
    add: (body = {}) => post({ url, body }),
  });

  const optionsType = (url) => ({
    list: () => get({ url }),
    add: (body = {}) => post({ url, body }),
  });

  const catalogue = (url) => ({
    get: (query = {}) => get({ url, query }),
    getFilters: (query = {}) => get({ url: `${url}/filters`, query }),
    like: ({ id, body }) => patch({ url, path: id, body }),
    item: (id) => get({ url, path: id }),
    create: (body) => post({ url, body }),
    deleteItem: (id) => remove({ url, path: id }),
    update: (id, body) => put({ url, path: id, body }),
    getFeedbacks: (query = {}) => get({ url, query }),
  });

  const deliveryPrice = (url) => ({
    get: () => get({ url }),
    // update: (body) => put({ url, body }),
  });

  const yandex = (url) => {
    return ({
      searchByAddress: (query = {}) => (get({ url, query: { ...query, apikey: YANDEX_TOCKEN, format: 'json' } })),
    });
  };

  const basket = (url) => {
    return ({
      ...crud(url),
      removeAll: () => remove({ url }),
      sync: (body) => post({ url: `${url}/sync`, body }),
    });
  };

  const alfaBank = (url) => ({
    register: (query = {}) => (
      post({
        url,
        path: 'register.do',
        query: {
          ...query,
          userName: AB_TEST_LOGIN,
          password: AB_TEST_PASSWORD,
          returnUrl: getSuccessPaymentUrl(),
          failUrl: getFailPaymentUrl(),
        },
      })),
  });

  return {
    get,
    remove,
    post,
    put,
    patch,
    login: login('/login'),
    auth: auth('/auth'),
    user: user('/user'),
    order: order('/order'),
    review: review('/review'),
    favorites: favorites('/favorite'),
    optionsType: optionsType('/type'),
    basket: basket('/cart'),
    category: crud('/category'),
    color: crud('/color'),
    deliveryPrice: deliveryPrice('/order/get-delivery-price'),
    role: crud('/role'),
    banner: banner('/banner'),
    catalogue: catalogue('/product'),
    yandex: yandex(YANDEX_GEOCODE_URL),
    alfaBank: alfaBank('/rest'),
  };
};

export default connect;

import { createAction } from './common';
import types from '../types';

export const getFavorites = createAction(types.FETCH_GET_FAVORITES);
export const setFavorites = createAction(types.SET_FAVORITES);
export const setFavoriteItem = createAction(types.SET_FAVORITE_ITEM);
export const setFavoritesLoading = createAction(types.SET_FAVORITES_LOADING);
export const delFavorites = createAction(types.DEL_FAVORITES); // clear
export const delAllFavorites = createAction(types.DEL_ALL_FAVORITES);
export const syncFavorites = createAction(types.FETCH_SYNC_FAVORITES);

export const delFavoriteItem = createAction(types.DEL_FAVORITE_ITEM);
export const setDelFavoriteItem = createAction(types.SET_DEL_FAVORITE_ITEM);

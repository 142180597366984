import React, { Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/breadCrumbs/ArrowBreadCrumbs.svg';
import './index.scss';

const BreadCrumbs = ({ list }) => {
  return (
    <div className="BreadCrumbsComponent">
      {
        list.map((el, i) => (
          <Fragment key={el.id}>
            <Link
              to={el.link}
              onClick={el.onClick}
              className={cn('breadCrumb', { last: !list[i + 1] })}
            >
              {el.title}
            </Link>
            {
              list[i + 1] && (
                <Arrow />
              )
            }
          </Fragment>
        ))
      }
    </div>
  );
};

BreadCrumbs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    link: PropTypes.string,
  })),
};

BreadCrumbs.defaultProps = {
  list: [],
};

export default BreadCrumbs;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { getBasketList } from '../../../actions/basket'; // delBasketList,
import { mountAuthComponent } from '../../../actions/auth';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { ReactComponent as Bag } from '../../../assets/icons/bag.svg';
import { ReactComponent as Like } from '../../../assets/icons/like.svg';
import { ReactComponent as Burger } from '../../../assets/icons/burger.svg';
import { ReactComponent as Profile } from '../../../assets/icons/profile.svg';
import { ReactComponent as InHome } from '../../../assets/main/inHome.svg';
import { MobileMenu } from './mobileMenu';
import {
  goToCatalogue, goToContacts, goToFavorites,
  goToProfile, goToBasket, // goToMain,
} from '../../../constants';
import './index.scss';
import { getFavorites } from '../../../actions/favorites'; // delFavorites,

const hasAccessToken = !!localStorage.getItem('accessToken');

const Header = (props) => {
  const {
    getBasketList, isAuthorized,
    basketList, getFavorites, // location, delFavorites, delBasketList,
  } = props;
  const { favoritesList, openAuth } = props;
  const [openMenu, setOpenMenu] = useState(false);
  // const mainHeader = useMemo(() => location.pathname === '/', [location]);
  const basketItemsCount = useMemo(() => (basketList ? basketList.length : 0), [basketList]);
  const favoritesItemsCount = useMemo(() => (
    favoritesList
      ? favoritesList.length
      : 0
  ), [favoritesList]);

  useEffect(() => {
    if (hasAccessToken) {
      if (isAuthorized) {
        getBasketList();
        getFavorites();
      }
    } else {
      getBasketList();
      getFavorites();
    }
    // return () => {
    //   delBasketList();
    //   delFavorites();
    // };
  }, [getBasketList, getFavorites, isAuthorized]); // delFavorites, delBasketList,

  const handleMenuStatus = useCallback(() => setOpenMenu(!openMenu), [openMenu]);

  return (
    // <header className={cn({ mainHeader })}>
    <header>
      <div className="menu">
        {/* <button onClick={goToMain}>Главная</button> */}
        <button onClick={goToCatalogue}>Каталог</button>
        <button onClick={goToContacts}>Контакты</button>
      </div>
      <div className="title">
        <InHome />
      </div>
      <div className="buttonContainer">
        <button
          type="button"
          onClick={handleMenuStatus}
          className="burger"
        >
          <Burger />
        </button>
        <button
          type="button"
          className="search"
        >
          <Search />
        </button>
        <button
          className="bag"
          onClick={goToBasket}
        >
          <Bag />
          {
            basketItemsCount > 0 && (
            <div
              className={cn('ItemsCount', {
                ItemsCountDoubleDigit: basketItemsCount.toString().length === 2,
                ItemsCountMaxCount: basketItemsCount > 99,
              })}
            >
              {basketItemsCount > 99 ? '+99' : basketItemsCount}
            </div>
            )
          }
        </button>
        <button
          className="like"
          type="button"
          onClick={goToFavorites}
        >
          <Like />
          {
            favoritesItemsCount > 0 && (
            <div
              className={cn('ItemsCount', {
                ItemsCountDoubleDigit: favoritesItemsCount.toString().length === 2,
                ItemsCountMaxCount: favoritesItemsCount > 99,
              })}
            >
              {favoritesItemsCount > 99 ? '+99' : favoritesItemsCount}
            </div>
            )
          }
        </button>
        <button
          className="profile"
          onClick={isAuthorized ? goToProfile : openAuth}
          // onClick={openAuth}
        >
          <Profile />
        </button>
      </div>
      <Drawer
        anchor="right"
        open={openMenu}
        onClose={handleMenuStatus}
      >
        <MobileMenu handleMenuStatus={handleMenuStatus} />
      </Drawer>
    </header>
  );
};

export const mapStateToProps = (state) => {
  // console.log('state', state);
  return {
    // location: state.router.location,
    basketList: state.basket.list,
    favoritesList: state.favorites.favorites,
    isAuthorized: !!state.profile.user,
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  getBasketList,
  getFavorites,
  // delBasketList,
  // delFavorites,
  openAuth: mountAuthComponent,
})(Header);

export default ConnectedComponent;

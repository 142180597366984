import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';

export const SelectMui = ({ options, label, id, ...props }) => {
  return (
    <FormControl variant="standard">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        {...props}
      >
        {
          options.map((el) => (
            <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

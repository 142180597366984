import { put } from 'redux-saga/effects';
import API from '../api';
import { setGoodsFilter, setGoods } from '../actions/salesman';
import { history } from '../store';
import { routes } from '../routes';
import { prepareFilters } from './catalogue';

export function* getListSalesmanSaga(action) {
  try {
    const { keepPreviousValues, saveFilters = false, query, id } = action.$payload;
    const { selectedSubCategory, selectedCategory, ...queryRest } = query;
    // category_id
    const json = yield API.rest.catalogue.get({
      ...queryRest,
      category_id: selectedSubCategory,
    });
    yield put(setGoods({ ...json, keepPreviousValues }));
    history.push(routes.salesman(id, { selectedSubCategory, selectedCategory, ...queryRest }));
    if (saveFilters) {
      yield put(setGoodsFilter(prepareFilters(json)));
    } // сохраняются не значения фильтров, а сами возможные фильтры
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

import types from '../types';

const initialState = {
  orderHistory: null,
  orderList: null,
  sendedOrderList: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORDER_LIST:
      return {
        ...state,
        orderList: action.$payload,
      };
    case types.SET_HISTORY_ORDERS_LIST:
      return {
        ...state,
        orderHistory: filtrationOrderHistory(action.$payload.data),
      };
    case types.CLEAR_HISTORY_ORDERS_LIST:
      return {
        ...state,
        orderHistory: null,
      };
    case types.SET_SENDED_ORDER_LIST:
      return {
        ...state,
        sendedOrderList: action.$payload.value,
      };
    case types.CLEAR_ORDER_LIST:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

const filtrationOrderHistory = (list) => list.filter((el) => el.products.length !== 0);

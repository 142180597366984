import fp from 'lodash/fp';
import { saveAs } from 'file-saver';
import {
  URL_BASE,
  URL_EMPTY,
} from '../api/constants';

export const CONTENT_TYPES = {
  json: 'application/json',
  formdata: 'multipart/form-data',
  form: 'application/x-www-form-urlencoded',
  patch: 'application/merge-patch+json',
  csv: 'text/csv',
  pdf: 'application/pdf',
  default: '*/*',
};

const URL_WITHOUT_VERSION = [
  '/oauth', '/payments', '/mock_recognition', '/recmndservice',
];

export const saveAsFile = (response, filename) => {
  saveAs(response.data, filename.slice(0, -1).slice(1));
};

export const checkContentType = fp.curry(
  // eslint-disable-next-line no-bitwise
  (response, contentType) => !!~(fp.split('; ', response?.headers['content-type'])[0] || '')
    .toLowerCase()
    .indexOf(contentType),
);

export const isHasBody = (method) => ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase());

export const getContentDispositionFilename = (response, fallback) => (
  fp.pipe(
    fp.defaultTo(''),
    fp.split(';'),
    fp.map(fp.trim),
    fp.find((v) => /^filename=/i.test(v)),
    fp.split('='),
    fp.last,
    fp.defaultTo(fallback),
  )(response.headers['content-disposition'])
);

export const isNeedVersionApi = (url) => fp.filter(
  // eslint-disable-next-line no-bitwise
  (v) => ~url.indexOf(v),
)(URL_WITHOUT_VERSION).length > 0;

export const getBaseUrl = (url) => {
  return url === '/sanctum/csrf-cookie'
    ? URL_EMPTY
    : URL_BASE;
};

export function getCurrentDomain() {
  // eslint-disable-next-line no-restricted-globals
  return `${location.hostname.split('.').slice(-2).join('.')}`;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name
    }=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${getCurrentDomain()}`;
  }
}

import React from 'react';
import { PhotoEditor } from '../../photoEditor';

export const PhotoField = (props) => {
  const {
    input: { onChange, value },
    label,
    needWidth,
    needHeight,
    meta,
    ...rest
  } = props;
  // console.log('props', props);
  return (
    <PhotoEditor
      {...rest}
      label={label}
      value={value}
      onChange={onChange}
      needWidth={needWidth}
      needHeight={needHeight}
    />
  );
};

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Bag } from '../../../../assets/icons/bag.svg';
import { ReactComponent as Like } from '../../../../assets/icons/like.svg';
import { ReactComponent as Profile } from '../../../../assets/icons/profile.svg';
import { ReactComponent as Cross } from '../../../../assets/icons/cross.svg';
import { ReactComponent as InHome } from '../../../../assets/main/inHome.svg';

import {
  goToCatalogue,
  goToContacts,
  goToFavorites,
  // goToMain,
  goToProfile,
  goToBasket,
} from '../../../../constants';
import { mountAuthComponent } from '../../../../actions/auth';
import './index.scss';

export const MobileMenu = ({ handleMenuStatus }) => {
  const isAuthorized = useSelector((state) => !!state.auth.accessToken);
  const dsp = useDispatch();

  const openAuthModal = () => {
    handleMenuStatus();
    dsp(mountAuthComponent());
  };

  // const prepareGoToMain = useCallback(() => {
  //   handleMenuStatus();
  //   goToMain();
  // }, [handleMenuStatus]);

  const prepareGoToCatalogue = useCallback(() => {
    handleMenuStatus();
    goToCatalogue();
  }, [handleMenuStatus]);

  const prepareGoToContacts = useCallback(() => {
    handleMenuStatus();
    goToContacts();
  }, [handleMenuStatus]);

  const prepareGoToFavorites = useCallback(() => {
    handleMenuStatus();
    goToFavorites();
  }, [handleMenuStatus]);

  const prepareGoToProfile = useCallback(() => {
    handleMenuStatus();
    goToProfile();
  }, [handleMenuStatus]);

  const prepareGoToBasket = useCallback(() => {
    handleMenuStatus();
    goToBasket();
  }, [handleMenuStatus]);

  return (
    <div className="MobileMenu">
      <div className="titleAndCross">
        <div className="title">
          <InHome />
        </div>
        <button className="close" onClick={handleMenuStatus}>
          <Cross />
        </button>
      </div>
      <div className="links">
        {/* <button onClick={prepareGoToMain}>Главная</button> */}
        <button onClick={prepareGoToCatalogue}>Каталог</button>
        <button onClick={prepareGoToContacts}>Контакты</button>
      </div>
      {/* <div className="search" /> */}
      <div className="buttons">
        <button
          className="bag"
          onClick={prepareGoToBasket}
        >
          <Bag />
          Корзина
        </button>
        <button
          className="link"
          onClick={prepareGoToFavorites}
        >
          <Like />
          Избранное
        </button>
        <button
          className="profile"
          onClick={isAuthorized ? prepareGoToProfile : openAuthModal}
        >
          <Profile />
          Личный кабинет
        </button>
      </div>
    </div>
  );
};

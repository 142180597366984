import React, { useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { isNil } from 'lodash';
import TextField from '../../../../components/finalForm/TextField';
import Select from '../../../../components/finalForm/Select';
import TextArea from '../../../../components/finalForm/TextArea';
import { PhotoList } from '../../../../components/finalForm/photolist';
import { emptyOption } from '../constantes';
import { ReactComponent as Trash } from '../../../../assets/icons/trash.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';
import validators from '../../../../helpers/form-validators/validators-lodash';
import './index.scss';

const Option = ({ name, optionsTypesOptions, index, fields, mutators }) => {
  const addOption = () => fields.push(emptyOption);
  const { value } = fields;

  useEffect(() => {
    mutators.setValue(`${name}.value`, value[index].value);
  }, [index, mutators, name, value[index].type_id]);

  return (
    <div className="options">
      <Field
        fullWidth
        component={TextField}
        name={`${name}.name`}
        type="text"
        validate={validators.required}
        label="Название атрибута"
      />
      <Field
        fullWidth
        component={TextField}
        name={`${name}.value`}
        type="text"
        validate={validators.pipe(
          validators.required,
          ...value[index].type_id === 2 ? [validators.is.numeric] : [],
        )}
        label="Значение атрибута товара"
      />
      <Field
        fullWidth
        component={Select}
        options={optionsTypesOptions}
        name={`${name}.type_id`}
            // type="select"
        singleChoice
        closeAfterSelection
        validate={validators.selectRequired}
        label="Тип атрибута товара"
      />
      <div className="buttonContainer">
        <button
          type="button"
          disabled={fields.value.length === 1}
          onClick={() => fields.remove(index)}
        >
          <Trash />
        </button>
        <button
          type="button"
          onClick={addOption}
        >
          <Plus />
        </button>
      </div>
    </div>
  );
};

const VALIDATORS = {
  price: validators.pipe(
    validators.is.numeric,
    validators.required,
  ),
  oldPrice: validators.is.numeric,
};

export const GoodForm = (props) => {
  const { optionsTypesOptions, addMode, mutators, values } = props;
  const { categoriesOptions, subcategoriesOptions: allSubcategoriesOptions, colorsOptions } = props;
  const categoriesValue = values.categories;
  const { price } = values;
  const subcategoriesOptions = useMemo(
    () => allSubcategoriesOptions.filter((el) => categoriesValue === el.parentId),
    [allSubcategoriesOptions, categoriesValue],
  );

  const currentPercent = useMemo(
    () => categoriesOptions.find((el) => el.id === categoriesValue)?.percent,
    [categoriesOptions, categoriesValue],
  );

  useEffect(() => {
    mutators.setValue('subcategories', undefined);
  }, [categoriesValue, mutators]);

  return (
    <div className="GoodForm">
      <Field
        fullWidth
        component={TextField}
        name="name"
        type="text"
        validate={validators.required}
        label="Название товара"
      />
      <Field
        fullWidth
        component={TextField}
        name="price"
        type="text"
        label="Цена (руб.)"
        validate={VALIDATORS.price}
        formatFunc={(value) => (value === '' ? '' : parseInt(value.replace(/[^\d]/g, ''), 10))}
      />
      {
        !isNil(currentPercent) && (
        <>
          <div className="clue">{`К товару будет добавлено ${currentPercent}% от цены товара в счет магазина`}</div>
          {!isNil(price) && <div className="clue">{`Итоговая цена будет составлять ${price + ((currentPercent * price) / 100)}`}</div>}
        </>
        )
      }
      <Field
        fullWidth
        component={TextField}
        name="old_price"
        type="text"
        label="Старая цена (руб.)"
        validate={VALIDATORS.oldPrice}
      />
      <Field
        fullWidth
        component={Select}
        options={categoriesOptions}
        name="categories"
        singleChoice
        closeAfterSelection
        type="select"
        required
        validate={validators.selectRequired}
        label="Выберите категорию товара"
      />
      <Field
        fullWidth
        component={Select}
        closeAfterSelection
        options={subcategoriesOptions}
        name="subcategories"
        singleChoice
        type="select"
        required
        disabled={!categoriesValue}
        validate={validators.selectRequired}
        label="Выберите подкатегорию товара"
      />
      <Field
        fullWidth
        component={Select}
        options={colorsOptions}
        name="colors"
        type="select"
        maxValues={2}
        label="Выберите цвет товара"
      />
      <div className="optionsLabel">Выберите атрибуты товара</div>
      <div className="note">
        Примечание:
        {' '}
        <span>все размеры указываются в см</span>
      </div>
      <FieldArray name="options">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <Option
                optionsTypesOptions={optionsTypesOptions}
                name={name}
                index={index}
                fields={fields}
                mutators={mutators}
              />
            ))}
          </>
        )}
      </FieldArray>
      <Field
        fullWidth
        component={TextArea}
        name="description"
        label="Описание"
        placeholder="Введите здесь описание товара"
      />
      <PhotoList
        name="previews"
        label="Фото для каталога (прямоугольное)"
        needWidth={322}
        needHeight={472}
      />
      <PhotoList
        name="images"
        label="Фото для страницы (квадратное)"
        needWidth={699}
        needHeight={607}
      />
      <button
        className="submit"
        type="submit"
      >
        {addMode ? 'Добавить товар' : 'Сохранить'}
      </button>
    </div>
  );
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { changeRoleUser, clearUsers, getRoles, getUsers } from '../../../../actions/user';
import { profileSelector } from '../../../../selectors/profile';
import './index.scss';
import { LoadingContainer } from '../../../../components/loadingContainer';
import { SelectMui } from '../../../../components/simpleComponents/selectMui';

const headCells = [
  {
    id: 'phone',
    label: 'Телефон',
  },
  {
    id: 'role',
    label: 'Роль',
  },
];

export const Roles = () => {
  const dsp = useDispatch();
  const {
    user, roles, changingRole,
    users: { list, page, total },
  } = useSelector(profileSelector);

  useEffect(() => {
    dsp(getRoles());
    dsp(getUsers({
      page: 1,
    }));
    return () => dsp(clearUsers());
  }, [dsp]);

  const handleChangePage = useCallback((e, newPage) => {
    dsp(getUsers({
      page: newPage + 1,
    }));
  }, [dsp]);

  const handleChangeRole = useCallback((id, role) => {
    dsp(changeRoleUser({ id, role }));
  }, [dsp]);

  const rolesOptions = useMemo(() => {
    return roles ? roles.map((el) => ({
      label: el.name,
      value: el.name,
    })) : [];
  }, [roles]);

  return (
    <Accordion className="rolesPage">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        Изменение ролей
      </AccordionSummary>
      <AccordionDetails>
        <Paper className="content">
          { changingRole && <LoadingContainer /> }
          <TableContainer>
            <Table
              sx={{ minWidth: 750, width: '100%' }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {list ? list.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        {row.phone}
                      </TableCell>
                      <TableCell>
                        <SelectMui
                          value={row.role}
                          onChange={(e) => {
                            handleChangeRole(row.id, e.target.value);
                          }}
                          label="Роль"
                          options={rolesOptions}
                          disabled={!user || user?.id === row.id}
                          // options={[]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }) : 'Загрузка пользователей...'}
              </TableBody>
            </Table>
          </TableContainer>
          {
            list && (
              <TablePagination
                rowsPerPageOptions={-1}
                component="div"
                count={total}
                labelRowsPerPage=""
                rowsPerPage={15}
                page={page - 1}
                onChangePage={handleChangePage}
              />
            )
          }
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

import React, { useCallback } from 'react';
import cn from 'classnames';
import './index.scss';

export const TextField = (props) => {
  const { error, onChange, formatFunc, meta, value } = props;

  const handleOnChange = useCallback((e) => {
    if (formatFunc) {
      onChange(formatFunc(e.target.value));
    } else {
      onChange(e);
    }
  }, [onChange, formatFunc]);

  return (
    <div className="TextField">
      <input
        className={cn({
          error: (meta && meta.error) || error,
        })}
        {...props}
        value={value} //  || ''
        onChange={handleOnChange}
      />
      {meta && meta.error && meta.touched && <span className="errorMessage">{meta.error}</span>}
    </div>
  );
};

import { history } from '../store';
import { routes } from '../routes';

export const localStorageKeyBasketItems = 'basketItemsList';
export const localStorageKeyFavoritesItems = 'favoritesList';

export const quantityRecords = 20;

export const maxQuantityInBasket = 30;

// export const goToMain = () => history.push(routes.home());
export const goToCatalogue = () => history.push(routes.catalogue());
export const goToContacts = () => history.push(routes.contacts());
export const goToBasket = () => history.push(routes.basket());
export const goToFavorites = () => history.push(routes.favorites());
export const goToProfile = () => history.push(routes.profile());

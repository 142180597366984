const types = {
  MOUNT_AUTH_COMPONENT: '[SAGS/AUTH]/MOUNT_AUTH_COMPONENT',
  UNMOUNT_AUTH_COMPONENT: '[SAGS/AUTH]/UNMOUNT_AUTH_COMPONENT',
  LOGIN: '[SAGS/AUTH]/LOGIN',
  SET_AUTH_DATA: '[SAGS/AUTH]/SET_AUTH_DATA',
  SET_ONLY_SESSION_TOKEN_AUTH: '[SAGS/AUTH]/SET_ONLY_SESSION_TOKEN_AUTH',
  CLEAR_AUTH_DATA: '[SAGS/AUTH]/CLEAR_AUTH_DATA',
  RECONNECT_AUTH_DATA: '[SAGS/AUTH]/RECONNECT_AUTH_DATA',
  SET_IS_LOADING_AUTH: '[SAGS/USER]/SET_IS_LOADING_AUTH',
  YANDEX_SEARCH_ADDRESS: '[YANDEX]/YANDEX_SEARCH_ADDRESS',
  SEARCH_COORDS: '[YANDEX]SEARCH_COORDS',
  YANDEX_SET_ADDRESS: '[YANDEX]YANDEX_SET_ADDRESS',
  SAVE_QUESTIONS_FIRST_STEP: 'SAVE_QUESTIONS_FIRST_STEP',
  SET_QUESTIONS_FIRST_STEP: 'SET_QUESTIONS_FIRST_STEP',
  SET_SEND_PHONE_LOGIN: 'SET_SEND_PHONE_LOGIN',
  SEND_PHONE_LOGIN: 'SEND_PHONE_LOGIN',
  SEND_CODE_LOGIN: 'SEND_CODE_LOGIN',

  // profile
  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',
  LOGOUT_USER: 'LOGOUT_USER',
  GET_ROLES: 'GET_ROLES',
  SET_ROLES: 'SET_ROLES',
  CLEAR_ROLES: 'CLEAR_ROLES',
  GET_USERS: 'GET_USERS',
  SET_USERS: 'SET_USERS',
  CLEAR_USERS: 'CLEAR_USERS',
  SET_ROLE_USER: 'SET_ROLE_USER',
  CHANGE_ROLE_USER: 'CHANGE_ROLE_USER',
  SET_CHANGING_USER_ROLE: 'SET_CHANGING_USER_ROLE',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',

  // Categories
  GET_CATEGORIES: 'GET_CATEGORIES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',
  ADD_CATEGORIES: 'ADD_CATEGORIES',
  DELETE_CATEGORIES: 'DELETE_CATEGORIES',
  SET_SELECT_CATEGORY: 'SET_SELECT_CATEGORY',
  SET_SELECT_SUBCATEGORY: 'SET_SELECT_SUBCATEGORY',
  // subcategories
  ADD_SUBCATEGORIES: 'ADD_SUBCATEGORIES',
  DEL_SUBCATEGORIES: 'DEL_SUBCATEGORIES',
  // salesman
  GET_SELLER_GOODS: 'GET_SELLER_GOODS',
  SET_SELLER_GOODS: 'SET_SELLER_GOODS',
  CLEAR_SELLER_GOODS: 'CLEAR_SELLER_GOODS',
  SET_SELLER_GOODS_FILTERS: 'SET_SELLER_GOODS_FILTERS',
  DEL_SELLER_GOODS_FILTERS: 'DEL_SELLER_GOODS_FILTERS',
  // Colors
  GET_COLORS: 'GET_COLORS',
  SET_COLORS: 'SET_COLORS',
  CLEAR_COLORS: 'CLEAR_COLORS',
  ADD_COLOR: 'ADD_COLOR',
  DEL_COLOR: 'DEL_COLOR',

  GET_CATALOGUE_LIST: 'GET_CATALOGUE_LIST',
  SET_CATALOGUE_LIST: 'SET_CATALOGUE_LIST',
  DEL_CATALOGUE_LIST: 'DEL_CATALOGUE_LIST',
  LIKE_CATALOGUE_ITEM: 'LIKE_CATALOGUE_ITEM',
  SET_LIKE_CATALOGUE_ITEM: 'SET_LIKE_CATALOGUE_ITEM',
  GET_CATALOGUE_FILTERS: 'GET_CATALOGUE_FILTERS',
  SET_CATALOGUE_FILTERS: 'SET_CATALOGUE_FILTERS',
  DEL_CATALOGUE_FILTERS: 'DEL_CATALOGUE_FILTERS',
  SET_SELECT_COMMON_CATEGORY: 'SET_SELECT_COMMON_CATEGORY',
  SET_SELECT_COMMON_SUBCATEGORY: 'SET_SELECT_COMMON_SUBCATEGORY',

  /* Basket */
  FETCH_GET_BASKET: 'FETCH_GET_BASKET',
  FETCH_CHANGE_BASKET: 'FETCH_CHANGE_BASKET',
  SET_BASKET: 'SET_BASKET',
  DEL_BASKET: 'DEL_BASKET',
  SET_DEL_BASKET: 'SET_DEL_BASKET',
  SET_LOADED_BASKET: 'SET_LOADED_BASKET',
  // Single item
  SELECT_BASKET_ITEM: 'SELECT_BASKET_ITEM',
  UPDATE_BASKET_ITEM: 'UPDATE_BASKET_ITEM',
  DELETE_BASKET_ITEM: 'DELETE_BASKET_ITEM',
  SET_DELETE_BASKET_ITEM: 'SET_DELETE_BASKET_ITEM',
  FETCH_ADD_BASKET_ITEM: 'FETCH_ADD_BASKET_ITEM',
  SET_BASKET_ITEM: 'SET_BASKET_ITEM',
  SET_BASKET_ITEM_LOADING: 'SET_BASKET_ITEM_LOADING',
  /* End basket */

  /* Banners */
  GET_COMMON_BANNERS: 'GET_COMMON_BANNERS',
  SET_COMMON_BANNERS: 'SET_COMMON_BANNERS',
  CLEAR_COMMON_BANNERS: 'CLEAR_COMMON_BANNERS',
  ADD_COMMON_BANNER: 'ADD_COMMON_BANNER',
  SET_ADDED_COMMON_BANNERS: 'SET_ADDED_COMMON_BANNERS',
  SET_ADDED_COMMON_BANNER_FLAG: 'SET_ADDED_COMMON_BANNER_FLAG',
  REMOVE_COMMON_BANNER: 'REMOVE_COMMON_BANNER',
  SET_REMOVED_COMMON_BANNER: 'SET_REMOVED_COMMON_BANNER',
  UPDATE_COMMON_BANNER: 'UPDATE_COMMON_BANNER',
  SET_UPDATED_COMMON_BANNER: 'SET_UPDATED_COMMON_BANNER',

  GET_SELLER_BANNERS: 'GET_SELLER_BANNERS',
  SET_SELLER_BANNERS: 'SET_SELLER_BANNERS',
  CLEAR_SELLER_BANNERS: 'CLEAR_SELLER_BANNERS',
  ADD_SELLER_BANNER: 'ADD_SELLER_BANNER',
  REMOVE_SELLER_BANNER: 'REMOVE_SELLER_BANNER',
  SET_ADDED_SELLER_BANNER: 'SET_ADDED_SELLER_BANNER',
  /* Banners end */

  /* goodPage */
  FETCH_GET_GOOD_DATA: 'GET_GOOD_DATA',
  FETCH_GET_FEEDBACKS: 'FETCH_GET_FEEDBACKS',
  CREATE_FEEDBACKS: 'CREATE_FEEDBACKS',
  FETCH_CREATE_PRODUCT: 'FETCH_CREATE_PRODUCT',
  FETCH_UPDATE_PRODUCT: 'FETCH_UPDATE_PRODUCT',
  FETCH_REMOVE_PRODUCT: 'FETCH_REMOVE_PRODUCT',
  SET_FEEDBACKS: 'SET_FEEDBACKS',
  SET_GOOD_DATA: 'SET_GOOD_DATA',
  SET_GOOD_LOADING_STATUS: 'SET_GOOD_LOADING_STATUS',
  SET_LOADING_FEEDBACKS: 'SET_LOADING_FEEDBACKS',
  SET_REMOVE_PRODUCT: 'SET_REMOVE_PRODUCT',
  SET_FLAG_REMOVED_PRODUCT: 'SET_FLAG_REMOVED_PRODUCT',
  SET_LOADING_FLAG_PRODUCT: 'SET_LOADING_FLAG_PRODUCT',
  DEL_GOOD_DATA: 'DEL_GOOD_DATA',
  SET_PREV_URL: 'SET_PREV_URL',
  /* goodPage end */

  /* favorites */
  FETCH_GET_FAVORITES: 'FETCH_GET_FAVORITES',
  FETCH_SYNC_FAVORITES: 'FETCH_SYNC_FAVORITES',
  SET_FAVORITES: 'SET_FAVORITES',
  SET_FAVORITES_LOADING: 'SET_FAVORITES_LOADING',
  DEL_FAVORITE_ITEM: 'DEL_FAVORITE_ITEM',
  DEL_FAVORITES: 'DEL_FAVORITES',
  DEL_ALL_FAVORITES: 'DEL_ALL_FAVORITES',
  SET_DEL_FAVORITE_ITEM: 'SET_DEL_FAVORITE_ITEM',
  SET_FAVORITE_ITEM: 'SET_FAVORITE_ITEM',
  /* favorites end */

  /* optionsTypes */
  GET_OPTIONS_TYPES: 'GET_OPTIONS_TYPES',
  SET_OPTIONS_TYPES: 'SET_OPTIONS_TYPES',
  CLEAR_OPTIONS_TYPES: 'CLEAR_OPTIONS_TYPES',
  ADD_OPTIONS_TYPES: 'ADD_OPTIONS_TYPES',
  /* optionsTypes end */

  /* order */
  SEND_ORDER_LIST: 'SEND_ORDER_LIST',
  SET_ORDER_LIST: 'SET_ORDER_LIST',
  SET_SENDED_ORDER_LIST: 'SET_SENDED_ORDER_LIST',
  CLEAR_ORDER_LIST: 'CLEAR_ORDER_LIST',
  GET_HISTORY_ORDERS_LIST: 'GET_HISTORY_ORDERS_LIST',
  SET_HISTORY_ORDERS_LIST: 'SET_HISTORY_ORDERS_LIST',
  CLEAR_HISTORY_ORDERS_LIST: 'CLEAR_HISTORY_ORDERS_LIST',
  /* order end */

  /* Notification */
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  /* Notification end */

  // delivery
  GET_DELIVERY_PRICE: 'GET_DELIVERY_PRICE',
  SET_DELIVERY_PRICE: 'SET_DELIVERY_PRICE',
  CLEAR_DELIVERY_PRICE: 'CLEAR_DELIVERY_PRICE',
  // UPDATE_DELIVERY_PRICE: 'UPDATE_DELIVERY_PRICE',
  // delivery end

  /* adultModal */
  SET_ADULT_MODAL_VALUE: 'SET_ADULT_MODAL_VALUE',
  SET_ADULT_VALUE: 'SET_ADULT_VALUE',
  CLEAR_ADULT_STORE: 'CLEAR_ADULT_STORE',
  /* adultModal end */

  redirectMethods: {
    push: 'push',
    replace: 'replace',
  },
};

export default types;

import React from 'react';
import { PlugBasketAndFavorite } from '../../../components/plugBasketAndFavorite';
import './index.scss';

export const OrderItem = ({ id, name, dimensions, desktopImage, mobileImage }) => {
  return (
    <div className="OrderItem">
      { mobileImage
        ? <img className="mobileImage" src={mobileImage} alt={`${id}_image_desktop`} />
        : (
          <div className="mobilePlug">
            <PlugBasketAndFavorite mobileMode />
          </div>
        )}
      { desktopImage
        ? <img className="desktopImage" src={desktopImage} alt={`${id}_image_desktop`} />
        : (
          <div className="desktopPlug">
            <PlugBasketAndFavorite />
          </div>
        )}
      <div className="nameAndDimensions">
        <div className="name">{name}</div>
        <div className="dimensions">{dimensions}</div>
      </div>
    </div>
  );
};

import React from 'react';
import { TextField } from '../../simpleComponents/textField';

const TextFieldFF = (props) => {
  const { input: { name, onChange, value, type }, label, meta, ...rest } = props;
  return (
    <TextField
      {...rest}
      name={name}
      // helperText={
      // meta.error && (meta.dirty || meta.submitFailed) && meta.touched ? meta.error : undefined
      // }
      placeholder={label}
      meta={meta}
      error={meta.error}
      onChange={onChange}
      value={value}
      type={type}
    />
  );
};

export default TextFieldFF;

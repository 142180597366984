import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom'; // Redirect
import { routes } from '../../routes';
import Header from './header';
import Footer from './footer';
import { AdultModal } from '../adultModal';
import { getUser } from '../../actions/user';
import AuthAndRegister from '../auth';
import { setAuthData } from '../../actions/auth';
import { Notification } from '../notification';
import './index.scss';
// import Profile from '../../pages/profile';

// const HomePage = lazy(() => import('../../pages/home'));
const Catalogue = lazy(() => import('../../pages/catalogue'));
const Contacts = lazy(() => import('../../pages/contacts'));
const Basket = lazy(() => import('../../pages/basket'));
const GoodPage = lazy(() => import('../../pages/goodPage'));
const Favorites = lazy(() => import('../../pages/favorites'));
const Profile = lazy(() => import('../../pages/profile'));
const Order = lazy(() => import('../../pages/order'));
const Salesman = lazy(() => import('../../pages/salesman'));
// const OrderEvaluation = lazy(() => import("../modal/OrderEvaluation"));

const Layout = ({ getUser, setAuthData, isAuth }) => {
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setAuthData({ accessToken });
    }
  }, [setAuthData]);

  useEffect(() => {
    if (isAuth) {
      getUser();
    }
  }, [getUser, isAuth]);

  return (
    <>
      <Header />
      <AuthAndRegister />
      <Notification />
      <AdultModal />
      <div className="layoutContainer">
        <Suspense fallback={<div />}>
          <Switch>
            {/* <Route */}
            {/*  exact */}
            {/*  path={routes.home()} */}
            {/*  component={HomePage} */}
            {/* /> */}
            <Route
              exact
              path={routes.catalogue()}
              component={Catalogue}
            />
            <Route
              exact
              path={routes.basket()}
              component={Basket}
            />
            <Route
              exact
              path={routes.goodPage(':id')}
              component={GoodPage}
            />
            <Route
              exact
              path={routes.favorites()}
              component={Favorites}
            />
            <Route
              exact
              path={routes.contacts()}
              component={Contacts}
            />
            <Route
              exact
              path={routes.order()}
              component={Order}
            />
            <Route
              exact
              path={routes.salesman(':id')}
              component={Salesman}
            />
            {
              isAuth && (
              <Route
                exact
                path={routes.profile()}
                component={Profile}
              />
              )
             }
            {/* <Route */}
            {/*  exact */}
            {/*  path={routes.profile()} */}
            {/*  render={() => (isAuth ? ( */}
            {/*    <Profile /> */}
            {/*  ) : ( */}
            {/*    <Redirect */}
            {/*      to={routes.home()} */}
            {/*    /> */}
            {/*  ))} */}
            {/* /> */}
            {/* { */}
            {/*  isAuth ? ( */}
            {/*    <Route */}
            {/*      exact */}
            {/*      path={routes.profile()} */}
            {/*      component={Profile} */}
            {/*    /> */}
            {/*  ) : null */}
            {/* } */}
            <Route
              path="*"
              render={({ location }) => (
                <h3 className="error404">
                  404:
                  {location.pathname}
                </h3>
              )}
            />
          </Switch>
        </Suspense>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: !!state.auth.accessToken,
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  getUser,
  setAuthData,
})(Layout);

export default ConnectedComponent;

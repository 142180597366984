import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { INITIAL_VALUES } from '../constantes';
import { createProduct } from '../../../../actions/goodPage';
import { GoodForm } from './form';
import './index.scss';

export const AddGood = ({ colors, categories, optionsTypes, subcategories }) => {
  let formReset;
  const dsp = useDispatch();
  const handleAddGood = useCallback((values) => {
    dsp(createProduct({ values, onFinish: formReset }));
  }, [dsp, formReset]);

  const colorsOptions = useMemo(
    () => (colors ? colors.map((el) => ({ name: el.name, id: el.id })) : []),
    [colors],
  );

  const categoriesOptions = useMemo(
    () => (
      categories
        ? categories.map((el) => ({ name: el.name, id: el.id, percent: el.percent }))
        : []
    ),
    [categories],
  );

  const subcategoriesOptions = useMemo(
    () => (subcategories ? subcategories.map((el) => ({
      name: el.name,
      id: el.id,
      parentId: el.parent_id,
    })) : []),
    [subcategories],
  );

  const optionsTypesOptions = useMemo(
    () => (optionsTypes ? optionsTypes.map((el) => ({ name: el.description, id: el.id })) : []),
    [optionsTypes],
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        Создать товар
      </AccordionSummary>
      <AccordionDetails>
        <div className="AddGood">
          <Form
            onSubmit={handleAddGood}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={INITIAL_VALUES}
            render={({ handleSubmit, form, values }) => {
              formReset = form.reset;
              return (
                <form onSubmit={handleSubmit}>
                  <GoodForm
                    mutators={form.mutators}
                    categoriesOptions={categoriesOptions}
                    subcategoriesOptions={subcategoriesOptions}
                    colorsOptions={colorsOptions}
                    optionsTypesOptions={optionsTypesOptions}
                    values={values}
                  />
                </form>
              );
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

import types from '../types';

const initialState = {
  message: null,
  type: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION:
      return {
        ...state,
        message: action.$payload.message,
        type: action.$payload.type,
      };
    case types.CLEAR_NOTIFICATION:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

import { createAction } from './common';
import types from '../types';

export const getBasketList = createAction(types.FETCH_GET_BASKET);
export const changeBasketList = createAction(types.FETCH_CHANGE_BASKET);
export const setBasketList = createAction(types.SET_BASKET);
export const delBasketList = createAction(types.DEL_BASKET);
export const setDelBasketList = createAction(types.SET_DEL_BASKET);
export const setLoadedBasket = createAction(types.SET_LOADED_BASKET);

// Single item
export const selectBasketItem = createAction(types.SELECT_BASKET_ITEM);
export const updateBasketItem = createAction(types.UPDATE_BASKET_ITEM);
export const deleteBasketItem = createAction(types.DELETE_BASKET_ITEM);
export const setDeleteBasketItem = createAction(types.SET_DELETE_BASKET_ITEM);
export const addBasketItem = createAction(types.FETCH_ADD_BASKET_ITEM);
export const setBasketItem = createAction(types.SET_BASKET_ITEM);
export const setBasketItemLoading = createAction(types.SET_BASKET_ITEM_LOADING);

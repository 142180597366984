import React, { useMemo, useRef, useState, useEffect } from 'react';
// import axios from 'axios';
import {
  ReactCrop,
} from 'react-image-crop';
import cn from 'classnames';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview, useDebounceEffect } from './utils';
import { isURL } from '../../helpers';
import { ScaleButtons } from './scaleButton';
import './index.scss';

const imageUrlToBase64 = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const PhotoEditor = ({ needWidth, needHeight, label, onChange, value }) => { // , value
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState(undefined);
  const [viewEditedImage, setViewEditedImage] = useState(true);
  const [scale, setScale] = useState(1);
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const initialFileRef = useRef(null);
  // const blobUrlRef = useRef('');
  const aspect = useMemo(() => needWidth / needHeight, [needHeight, needWidth]);
  const initialCrop = useMemo(() => ({
    height: needHeight,
    unit: 'px',
    width: needWidth,
    x: 0,
    y: 0,
  }), [needHeight, needWidth]);

  const imgRef = useRef(null);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0,
        );
      }
    },
    100,
    [completedCrop, scale],
  );

  useEffect(() => {
    // console.log('value', value);
    // console.log('imgSrc', imgSrc);
    // console.log('valueisURL', value ? isURL(value) : value);
    if (value && value !== imgSrc) {
      if (!isURL(value)) {
        setImgSrc(value);
      } else {
        // console.log('isURL value', value);
        imageUrlToBase64(value)
          .then((data) => {
            setImgSrc(data);
            // setViewEditedImage(false);
          });
      }
      //   deleteImage();
    }
    if (value === '' && value !== imgSrc && imgSrc !== '') {
      deleteImage();
    }
  }, [value]);

  const onImageLoad = () => {
    if (aspect) {
      setCrop(initialCrop);
    }
  };

  const applyAndEditImage = () => {
    if (viewEditedImage) {
      onChange(previewCanvasRef.current.toDataURL());
      // previewCanvasRef.current.toBlob((blob) => {
      //   if (!blob) {
      //     throw new Error('Failed to create blob');
      //   }
      //   if (blobUrlRef.current) {
      //     URL.revokeObjectURL(blobUrlRef.current);
      //   }
      //   blobUrlRef.current = URL.createObjectURL(blob);
      //   // onChange(blobUrlRef.current);
      // });
    }
    setViewEditedImage(!viewEditedImage);
  };

  const onLoadInitialFile = () => {
    initialFileRef.current.click();
  };

  const deleteImage = () => {
    initialFileRef.current.value = null;
    previewCanvasRef.current.value = null;
    // imgRef.current.currentSrc = null;
    setImgSrc('');
    setScale(1);
    setViewEditedImage(true);
    setCrop(undefined);
    setCompletedCrop(null);
    onChange(null);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        // console.log('reader.result?.toString()', reader.result?.toString());
        setImgSrc(reader.result?.toString() || '');
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // console.log('initialFileRef', initialFileRef?.current?.value);

  return (
    <div className="PhotoEditor">
      {
        label && (
        <div className="PhotoEditor_label">
          {label}
        </div>
        )
      }
      <div className="addAndDelImage">
        {
          imgSrc ? (
            <button
              onClick={deleteImage}
              type="button"
            >
              Удалить изображение
            </button>
          ) : (
            <button
              onClick={onLoadInitialFile}
              type="button"
            >
              Загрузить изображение
            </button>
          )
        }
      </div>
      <input
        ref={initialFileRef}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
      />
      {!!imgSrc && (
      <div className={cn('editedImage', { editedImage_notView: !viewEditedImage })}>
        <ScaleButtons
          scale={scale}
          setScale={setScale}
        />
        <ReactCrop
          minWidth={needWidth}
          minHeight={needHeight}
          maxWidth={needWidth}
          maxHeight={needHeight}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale})` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </div>
      )}
      {!!completedCrop && (
        <div className="readyImage">
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
          <button
            className="apply"
            onClick={applyAndEditImage}
            type="button"
          >
            {viewEditedImage ? 'Применить' : 'Изменить'}
          </button>
        </div>
      )}
    </div>
  );
};

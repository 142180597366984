import { put } from 'redux-saga/effects';
import API from '../api';
import {
  setFeedbacks, setGoodData, setGoodLoadingStatus, setLoadingFeedbacks,
} from '../actions/goodPage';

export function* getGoodDataSaga(action) {
  try {
    const json = yield API.rest.catalogue.item(action.$payload.id);
    const preparedOptions = json.options.reduce((acc, el) => {
      if (el.name === 'Ширина') {
        acc.width = el.value;
        return acc;
      }
      if (el.name === 'Длина') {
        acc.length = el.value;
        return acc;
      }
      if (el.name === 'Высота') {
        acc.height = el.value;
        return acc;
      }
      acc.otherOptions.push(el);
      return acc;
    }, { otherOptions: [] });
    yield put(setGoodData({ ...json, preparedOptions }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setGoodLoadingStatus({ value: false }));
  }
}

export function* getFeedbacksSaga(action) {
  try {
    const json = yield API.rest.review.item(action.$payload);
    yield put(setFeedbacks(json.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadingFeedbacks({ value: false }));
  }
}

export function* createFeedbacksSaga(action) {
  try {
    const json = yield API.rest.review.add(action.$payload.body);
    if (json) {
      action.$payload.closeModal();
      const getJson = yield API.rest.review.item({
        product_id: action.$payload.body.product_id,
        hasText: true,
      });
      yield put(setFeedbacks(getJson.data));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadingFeedbacks({ value: false }));
    yield action.$payload?.onFail?.();
  }
}

import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Modal } from '@material-ui/core';
import { SendingCode } from './sendingCode';
import { sendCodeLogin, sendPhoneLogin, unmountAuthComponent } from '../../actions/auth';
import { MOBILE_BREAK_POINT } from '../../api/constants';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import validators from '../../helpers/form-validators/validators-lodash';
import EmailModeFields from './emailMode';
import AuthModeFields from './authModeFields';
import PhoneMode from './phoneMode';
import './index.scss';

const getMountedAuthComp = (state) => state.auth.mountedAuthComp;

function AuthAndRegister() {
  const [mode, setMode] = useState('auth');// что мы хотим? войти или авторизовтаься. Работает при AUTH_MODE===EMAIL
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState('dataCapture');

  const authorizationAndRegistrationMode = window?.env?.AUTH_MODE || 'PHONE';
  let resetForm = () => {};
  const dsp = useDispatch();
  const mountedAuthComp = useSelector(getMountedAuthComp);

  const onClose = useCallback(() => {
    resetForm();
    setStep('dataCapture');
    setMode('auth');
    dsp(unmountAuthComponent());
  }, [resetForm, dsp]);

  const setRegister = useCallback(() => {
    resetForm();
    setMode('register');
  }, [resetForm]);

  const setAuth = useCallback(() => {
    setMode('auth');
    resetForm();
  }, [resetForm]);

  const title = useMemo(() => {
    if (authorizationAndRegistrationMode === 'EMAIL') {
      return mode === 'auth' ? 'Вход' : 'Создать аккаунт';
    }
    return 'Создайте аккаунт или авторизуйтесь, введя свой телефон';
  }, [authorizationAndRegistrationMode, mode]);

  const submitBtnContent = useMemo(() => {
    if (authorizationAndRegistrationMode === 'EMAIL') return mode === 'auth' ? 'Вход' : 'создать аккаунт';
    if (authorizationAndRegistrationMode === 'PHONE') return step === 'dataCapture' ? 'Вход' : 'Подтвердить';
    return 'Войти';
  }, [authorizationAndRegistrationMode, mode, step]);

  const onSubmit = (values) => {
    if (authorizationAndRegistrationMode === 'PHONE' && step === 'dataCapture') {
      dsp(sendPhoneLogin({
        phone: values.phone.replace(/[( )\\-]/g, ''),
        onFinish: () => {
          setStep('sendingCode');
        },
      }));
    }
    if (authorizationAndRegistrationMode === 'PHONE' && step === 'sendingCode') {
      dsp(sendCodeLogin({
        code: values.code,
        phone: values.phone.replace(/[( )\\-]/g, ''),
        device: window.innerWidth > MOBILE_BREAK_POINT ? 'Web' : 'Mobile',
        onFinish: onClose,
      }));
    }
  };

  const formValidate = (values) => {
    const errors = {};
    if (
      authorizationAndRegistrationMode === 'PHONE'
        && step === 'dataCapture'
        && validators.is.phone(values.phone)
    ) {
      errors.phone = validators.is.phone(values.phone);
    }
    if (
      authorizationAndRegistrationMode === 'PHONE'
        && step === 'dataCapture'
        && !values.phone
    ) {
      errors.phone = 'Введите ваш телефон';
    }
    if (
      authorizationAndRegistrationMode === 'PHONE'
        && step === 'sendingCode'
        && !values.code
    ) {
      errors.code = 'Введите код';
    }
    return errors;
  };

  return (
    <Modal
      className="AuthAndRegister"
      open={mountedAuthComp}
      onClose={onClose}
    >
      <div className="AuthAndRegister__content">
        <button type="button" className="closeModal" onClick={onClose}>
          <Cross />
        </button>
        {
          authorizationAndRegistrationMode === 'EMAIL' && (
          <div className="selectMode">
            <button
              className={cn('enter', { active: mode === 'auth' })}
              onClick={setAuth}
            >
              Вход
            </button>
            <button
              className={cn('setRegister', { active: mode === 'register' })}
              onClick={setRegister}
            >
              Создать аккаунт
            </button>
          </div>
          )
        }
        <div className="title">
          {title}
        </div>
        <Form
          onSubmit={onSubmit}
          validate={formValidate}
          render={({ handleSubmit, form }) => {
            resetForm = form.reset;
            return (
              <form onSubmit={handleSubmit}>
                {
                  (authorizationAndRegistrationMode === 'EMAIL' && mode === 'auth') && (
                    <AuthModeFields />
                  )
                }
                {
                  (authorizationAndRegistrationMode === 'EMAIL' && mode === 'register' && step === 'dataCapture') && (
                    <EmailModeFields />
                  )
                }
                {
                  (authorizationAndRegistrationMode === 'PHONE' && step === 'sendingCode') && (
                    <SendingCode />
                  )
                }
                {
                  authorizationAndRegistrationMode === 'PHONE' && step === 'dataCapture' && (
                    <PhoneMode />
                  )
                }
                {(mode === 'auth' && authorizationAndRegistrationMode === 'EMAIL') && (
                  <div className="forgotPass">
                    <button>Забыли пароль?</button>
                  </div>
                )}
                <button
                  className={cn('primaryButton submit', { createAccMode: mode === 'register' })}
                  type="submit"
                >
                  {submitBtnContent}
                </button>
              </form>
            );
          }}
        />
      </div>
    </Modal>
  );
}

export default AuthAndRegister;

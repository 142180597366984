import types from '../types';

const initialState = {
  selectedCategory: null,
  selectedSubCategory: null,
  totalPages: null,
  totalRecords: null,
  list: null,
  filters: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.$payload.id,
      };
    case types.SET_SELECT_SUBCATEGORY:
      return {
        ...state,
        selectedSubCategory: action.$payload.id,
      };
    case types.SET_SELLER_GOODS:
      // eslint-disable-next-line no-case-declarations
      const catalogueList = action.$payload.products.data;
      // const catalogueList = parseCatalogueList(action.$payload.products.data);
      return {
        ...state,
        list: action.$payload.keepPreviousValues
          ? [...state.list ? state.list : [], ...catalogueList]
          : [...catalogueList],
        totalPages: action.$payload.products.last_page,
        // totalPages: Math.ceil(action.$payload.products.total / 10),
        totalRecords: action.$payload.products.total,
      };
    case types.CLEAR_SELLER_GOODS:
      return {
        ...state,
        list: null,
        totalPages: null,
        totalRecords: null,
      };
    case types.SET_SELLER_GOODS_FILTERS:
      return {
        ...state,
        filters: action.$payload,
      };
    case types.DEL_SELLER_GOODS_FILTERS:
      return {
        ...state,
        filters: null,
      };
    default:
      return state;
  }
};

export default reducer;

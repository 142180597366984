import { createAction } from './common';
import types from '../types';

export const getCatalogueList = createAction(types.GET_CATALOGUE_LIST);
export const setCatalogueList = createAction(types.SET_CATALOGUE_LIST);
export const delCatalogueList = createAction(types.DEL_CATALOGUE_LIST);
export const likeCatalogueItem = createAction(types.LIKE_CATALOGUE_ITEM);
export const setLikeCatalogueItem = createAction(types.SET_LIKE_CATALOGUE_ITEM);
// export const getCatalogueFilters = createAction(types.GET_CATALOGUE_FILTERS);
export const setCatalogueFilters = createAction(types.SET_CATALOGUE_FILTERS);
// export const delCatalogueFilters = createAction(types.DEL_CATALOGUE_FILTERS);

export const setSelectCommonCategory = createAction(types.SET_SELECT_COMMON_CATEGORY);
export const setSelectCommonSubCategory = createAction(types.SET_SELECT_COMMON_SUBCATEGORY);

export const updateProduct = createAction(types.FETCH_UPDATE_PRODUCT);
export const removeProduct = createAction(types.FETCH_REMOVE_PRODUCT);
export const setRemoveProduct = createAction(types.SET_REMOVE_PRODUCT);
export const setFlagRemovedProduct = createAction(types.SET_FLAG_REMOVED_PRODUCT);
export const setLoadingFlagProduct = createAction(types.SET_LOADING_FLAG_PRODUCT);

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { notificationSelector } from '../../selectors/notification';
import { clearNotification } from '../../actions/notification';

export const Notification = () => {
  const { message, type } = useSelector(notificationSelector);
  const dsp = useDispatch();
  const handleClose = useCallback(() => dsp(clearNotification()), [dsp]);
  const open = !isNil(message);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {
        (message && type) && (
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
        )
      }
    </Snackbar>
  );
};

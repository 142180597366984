import React from 'react';
import './index.scss';

export const TextArea = (props) => {
  const { label, meta, ...rest } = props;
  return (
    <div className="TextArea">
      <div className="label">{label}</div>
      <textarea
        {...rest}
      />
      {meta && meta.error && meta.touched && <span className="errorMessage">{meta.error}</span>}
    </div>
  );
};

import { put, select } from 'redux-saga/effects';
import { uniqBy } from 'lodash';
import API from '../api';
import {
  setBasketItem,
  setBasketItemLoading,
  setBasketList,
  setDelBasketList,
  setDeleteBasketItem,
  setLoadedBasket,
} from '../actions/basket';
import { getDataFromLocalStorage } from '../helpers';
import { localStorageKeyBasketItems } from '../constants';
import { isAuthorizedSelector } from '../selectors/profile';
import { basketListSelector } from '../selectors/basket';
import { setNotification } from '../actions/notification';

export function* getBasketListSaga() {
  try {
    const isAuthorized = yield select(isAuthorizedSelector);
    let finalDataList = [];

    const dataFromLocalStorage = getDataFromLocalStorage(localStorageKeyBasketItems);
    if (isAuthorized) {
      const json = yield API.rest.basket.list();
      const items = parseBasketList(json);
      const itemIds = dataFromLocalStorage.map((el) => el.productId);
      finalDataList = [
        ...items.filter((el) => !itemIds.includes(el.productId)),
        ...dataFromLocalStorage,
      ];
    } else {
      finalDataList = dataFromLocalStorage;
    }
    yield put(setBasketList(finalDataList));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadedBasket());
  }
}

export function* addBasketItemSaga(action) {
  try {
    const { id, count, bodyForLocalStorage } = action.$payload;
    const isAuthorized = yield select(isAuthorizedSelector);
    if (isAuthorized) {
      const json = yield API.rest.basket.create({ product_id: id, count });
      yield put(setBasketItem({
        ...bodyForLocalStorage,
        id: json.id,
        count: json.count,
      }));
    } else {
      const basketInLocalStorage = getDataFromLocalStorage(localStorageKeyBasketItems);
      localStorage.setItem(localStorageKeyBasketItems, JSON.stringify([
        ...basketInLocalStorage,
        bodyForLocalStorage,
      ]));
      yield put(setBasketItem({
        ...bodyForLocalStorage,
      }));
    }
    yield put(setBasketItemLoading({ value: false }));
  } catch (err) {
    yield put(setBasketItemLoading({ value: false }));
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* changeBasketListSaga() {
  try {
    // const { isAuthorized, newItemList } = action;
    const basketListFromLocalStorage = getDataFromLocalStorage(localStorageKeyBasketItems);
    const basketList = yield select(basketListSelector);
    // if (isAuthorized) {
    const products = uniqBy([
      ...basketListFromLocalStorage || [],
      ...basketList || [],
    ], 'productId').map((el) => ({ id: el.productId, count: el.count }));
    if (products.length > 0) {
      yield API.rest.basket.sync({ products });
      localStorage.setItem(localStorageKeyBasketItems, JSON.stringify([]));
      // const json = yield API.rest.basket.sync({ products });
      // console.log('changeBasketListSaga json', json);
    }
    // localStorage.removeItem(localStorageKeyBasketItems);
    // } else {
    //   localStorage.setItem(localStorageKeyBasketItems, newItemList);
    // }
    // yield put(setBasketList(newItemList));
    // yield put(setBasketList());
  } catch (err) {
    yield put(setNotification({
      type: 'error',
      message: 'Ошибка синхронизации корзины',
    }));
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadedBasket());
  }
}

export function* deleteBasketItemSaga(action) {
  try {
    const { id, productId } = action.$payload;
    const basketInLocalStorage = getDataFromLocalStorage(localStorageKeyBasketItems);
    if (basketInLocalStorage.length !== 0) {
      localStorage.setItem(localStorageKeyBasketItems, JSON.stringify([
        ...basketInLocalStorage.filter((el) => productId !== el.productId),
      ]));
    }
    const isAuthorized = yield select(isAuthorizedSelector);
    if (isAuthorized) {
      yield API.rest.basket.remove(id); // !
    }
    yield put(setDeleteBasketItem({
      id,
      productId,
      isAuthorized,
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setLoadedBasket());
  }
}

export function* deleteBasketListSaga(action) {
  try {
    const { isAuthorized } = action.$payload;
    if (isAuthorized) {
      yield API.rest.basket.removeAll();
    }
    localStorage.setItem(localStorageKeyBasketItems, JSON.stringify([]));
    yield put(setDelBasketList());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

const parseBasketList = (data) => data?.map((el) => ({
  id: el.id,
  productId: el.product_id,
  name: el.product.name,
  // image: el.product?.images[0]?.url,
  mobileImage: el.product?.previews[0]?.url || null,
  desktopImage: el.product?.images[0]?.url || null,
  price: el.product.price,
  oldPrice: el.product.old_price,
  count: el.count,
  // colors: ['brown', 'oak'],
}));

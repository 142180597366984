import React from 'react';
import cn from 'classnames';
import { ReactComponent as Plug } from '../../assets/icons/noImageBasketFavorite.svg';
import './index.scss';

export const PlugBasketAndFavorite = ({ mobileMode }) => {
  return (
    <div className={cn('plugBasketAndFavorite', { desktopOnly: !mobileMode })}>
      <Plug />
    </div>
  );
};

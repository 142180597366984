import { uniq } from 'lodash';
import types from '../types';

const summaryInitial = {
  sum: 0,
};

const initialState = {
  list: null,
  loading: false,
  summary: { ...summaryInitial },
  selectedIds: [],
  loadingItem: false,
};

const updateSummary = (state) => {
  return ({
    ...state,
    summary: state.list
      ?.filter((el) => state.selectedIds?.includes(el.productId))
      .reduce(
        (acc, el) => {
          return ({
            sum: acc.sum + el.price * el.count,
          });
        },
        { ...summaryInitial },
      )
        || { ...summaryInitial },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_GET_BASKET:
      return {
        ...state,
        loading: true,
      };
    case types.SET_LOADED_BASKET:
      return {
        ...state,
        loading: false,
      };
    case types.FETCH_ADD_BASKET_ITEM:
      return {
        ...state,
        loadingItem: true,
      };
    case types.SET_BASKET_ITEM_LOADING:
      return {
        ...state,
        loadingItem: action.$payload.value,
      };
    case types.SET_BASKET_ITEM:
      return updateSummary({
        ...state,
        list: [...state.list, action.$payload],
        selectedIds: [...state.selectedIds, action.$payload.productId],
      });
    case types.SET_BASKET:
      return updateSummary({
        ...state,
        list: action.$payload,
        selectedIds: action.$payload.map(({ productId }) => productId),
      });
    case types.SELECT_BASKET_ITEM:
      return updateSummary({
        ...state,
        selectedIds:
          action.$payload.state
            ? uniq([...state.selectedIds, action.$payload.id])
            : state.selectedIds.filter((el) => el !== action.$payload.id),
      });
    case types.UPDATE_BASKET_ITEM: {
      const list = state.list.map((el) => {
        if (el.id === action.$payload.id) {
          return {
            ...el,
            ...action.$payload.changes,
          };
        }
        return el;
      });
      return updateSummary({
        ...state,
        list,
      });
    }
    case types.SET_DELETE_BASKET_ITEM:
      if (action.$payload.isAuthorized) {
        return updateSummary({
          ...state,
          list: state.list.filter((el) => el.id !== action.$payload.id),
        });
      }
      return updateSummary({
        ...state,
        list: state.list.filter((el) => el.productId !== action.$payload.productId),
      });
    case types.SET_DEL_BASKET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

import { createAction } from './common';
import types from '../types';

export const login = createAction(types.LOGIN);
export const mountAuthComponent = createAction(types.MOUNT_AUTH_COMPONENT);
export const unmountAuthComponent = createAction(types.UNMOUNT_AUTH_COMPONENT);
export const setAuthData = createAction(types.SET_AUTH_DATA);
export const clearAuthData = createAction(types.CLEAR_AUTH_DATA);
export const reconnect = createAction(types.RECONNECT_AUTH_DATA);
export const setSendPhoneLogin = createAction(types.SET_SEND_PHONE_LOGIN);
export const sendPhoneLogin = createAction(types.SEND_PHONE_LOGIN);
export const sendCodeLogin = createAction(types.SEND_CODE_LOGIN);
// export const getUser = createAction( types.GET_USER );
// export const setUser = createAction( types.SET_USER );
// export const clearCurrentUser = createAction( types.CLEAR_CURRENT_USER );
// export const sendPhoneLogin = createAction( types.SEND_PHONE_LOGIN );
// export const setSendPhoneLogin = createAction( types.SET_SEND_PHONE_LOGIN );
export const setOnlySessionToken = createAction(types.SET_ONLY_SESSION_TOKEN_AUTH);
// export const setNotSendPhoneLogin = createAction( types.SET_NOT_SEND_PHONE_LOGIN );
// export const sendCodeLogin = createAction( types.SEND_CODE_LOGIN );
// export const setContact = createAction( types.SET_CONTACT );
// export const updateCurrentUser = createAction( types.UPDATE_CURRENT_USER );
// export const updateCurrentUserMessages = createAction( types.UPDATE_CURRENT_USER_MESSAGES );

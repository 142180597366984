import types from '../types';

const initialState = {
  deliveryPrice: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DELIVERY_PRICE:
      return {
        ...state,
        deliveryPrice: parseInt(action.$payload.delivery_price, 10),
      };
    case types.CLEAR_DELIVERY_PRICE:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

import qs from 'qs';

function request(url) {
  return (path = '', query = {}) => {
    const query_string = qs.stringify(query);
    return `${url}${path ? `/${path}` : ''}${query_string ? `?${query_string}` : ''}`;
  };
}

export const routes = {
  // home: request('/'),
  catalogue: request('/'),
  contacts: request('/contacts'),
  basket: request('/basket'),
  goodPage: request('/goodPage'),
  order: request('/order'),
  favorites: request('/favorites'),
  profile: request('/profile'),
  salesman: request('/salesman'),
};

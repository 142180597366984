import { put, select } from 'redux-saga/effects';
import { uniqBy } from 'lodash';
import API from '../api';
import { setDelFavoriteItem, setFavorites, setFavoritesLoading } from '../actions/favorites';
import { getDataFromLocalStorage } from '../helpers';
import { localStorageKeyFavoritesItems } from '../constants';
import { isAuthorizedSelector } from '../selectors/profile';
import { favoritesSelector } from '../selectors/favorites';

export function* removeAllFavoritesSaga({ $payload }) {
  try {
    const { isAuthorized } = $payload;
    if (isAuthorized) {
      yield API.rest.favorites.delAll();
    }
    yield put(setFavorites({ data: [] }));
    localStorage.setItem(localStorageKeyFavoritesItems, JSON.stringify([]));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* removeFavoriteItemSaga({ $payload }) {
  try {
    const { id, productId } = $payload;
    const isAuthorized = yield select(isAuthorizedSelector);
    if (isAuthorized) {
      yield API.rest.favorites.del({ id: productId });
    }
    yield put(setDelFavoriteItem({ id, productId, isAuthorized }));
    const favoritesInLocalStorage = getDataFromLocalStorage(localStorageKeyFavoritesItems);
    if (favoritesInLocalStorage.length !== 0) {
      localStorage.setItem(localStorageKeyFavoritesItems, JSON.stringify([
        ...favoritesInLocalStorage.filter((el) => productId !== el.productId),
      ]));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* syncFavoritesSaga() {
  try {
    const favoritesFromLocalStorage = getDataFromLocalStorage(localStorageKeyFavoritesItems);
    const { favorites } = yield select(favoritesSelector);
    const products = uniqBy([
      ...favoritesFromLocalStorage || [],
      ...favorites || [],
    ], 'productId').map(({ productId }) => productId);
    if (products.length > 0) {
      yield API.rest.favorites.sync({ favorites: products });
      localStorage.setItem(localStorageKeyFavoritesItems, JSON.stringify([]));
      // const json = yield API.rest.favorites.sync({ products });
      // console.log('syncFavoritesSaga json', json);
    }
    // yield put(setFavorites([]));
    // localStorage.removeItem(localStorageKeyFavoritesItems);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* getFavoritesSaga() {
  try {
    const isAuthorized = yield select(isAuthorizedSelector);
    if (isAuthorized) {
      const json = yield API.rest.favorites.list();
      yield put(setFavorites({ data: json }));
    } else {
      const favoritesFromLocalStorage = getDataFromLocalStorage(localStorageKeyFavoritesItems);
      yield put(setFavorites({ data: favoritesFromLocalStorage, isLocalStorageData: true }));
    }
    yield put(setFavoritesLoading({ value: false }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(setFavoritesLoading({ value: false }));
  }
}

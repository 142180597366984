import types from '../types';

const initialState = {
  goodData: null,
  loading: false,
  feedbacks: null,
  feedbacksLoading: false,
  prevUrlIsCatalogue: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PREV_URL:
      return {
        ...state,
        prevUrlIsCatalogue: action.$payload.value,
      };
    case types.FETCH_GET_GOOD_DATA:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_GET_FEEDBACKS:
      return {
        ...state,
        feedbacksLoading: true,
      };
    case types.SET_GOOD_LOADING_STATUS:
      return {
        ...state,
        // eslint-disable-next-line max-len
        loading: action.$payload.value, // проще нельзя, т.к. createAction вернет пустой объект (см. ф. createAction)
      };
    case types.SET_LOADING_FEEDBACKS:
      return {
        ...state,
        feedbacksLoading: action.$payload.value,
      };
    case types.SET_GOOD_DATA:
      return {
        ...state,
        goodData: action.$payload,
        loading: false,
      };
    case types.SET_FEEDBACKS:
      return {
        ...state,
        feedbacks: action.$payload,
        feedbacksLoading: false,
      };
    case types.DEL_GOOD_DATA:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
